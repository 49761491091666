import Component from 'navigation/component/Component'
import './ProductZoom.scss'
import { bindMethod } from 'helpers/bind'
import scroll from 'core/scroll'
import { dimensions } from 'helpers/resize'
import mqStore from 'stores/mqStore'

import ProductSuperZoom from './ProductSuperZoom'

type ProductZoomType = {
  refs: {
    zoomClose: HTMLElement
    zoomThumbItems: HTMLElement
    zoomImageContainer: HTMLElement
    images: HTMLImageElement[]
  }
  modules: {
    superzoom: ProductSuperZoom | null
  }
}

class ProductZoom extends Component<ProductZoomType> {
  private thumbFollowHeight: number

  constructor (el: HTMLElement) {
    super(el)
    this.bindRefs()

    this.thumbFollowHeight = 0
  }

  bindEvents (add: boolean): void {
    const method = bindMethod(add)
    const emitterMethod = add ? 'on' : 'off'

    this.el[method]('scroll', this.onScroll)
    this.refs.zoomClose[method]('click', this.onClose)
    this.refs.zoomThumbItems[method]('click', this.onThumbClick)
    this.refs.images.forEach(img => {
      img[method]('click', this.onImageClick)
    })

    this.modules.superzoom?.[emitterMethod]('unzoomed', this.onUnzoomed)
  }

  onUnzoomed = (): void => {
    this.el.classList.remove('is-zoomed')
    if (this.modules.superzoom) this.modules.superzoom.flush()
  }

  onScroll = (): void => {
    const scrollableHeight = this.el.scrollHeight
    const scrollY = this.el.scrollTop
    const thumbTop = (scrollY / scrollableHeight) * 100

    const thumbFollowHeightPercentage =
      (this.thumbFollowHeight / this.refs.zoomThumbItems.scrollHeight) * 100

    const adjustedThumbTop = Math.min(thumbTop, 100 - thumbFollowHeightPercentage)

    this.el.style.setProperty('--zoom-thumb-top', `${adjustedThumbTop}%`)
  }

  onThumbClick = (event: Event): void => {
    const mouseEvent = event as MouseEvent // Cast event to MouseEvent
    const thumbClickPosition = mouseEvent.clientY - (this.refs.zoomThumbItems.getBoundingClientRect().top)
    const thumbClickRatio = thumbClickPosition / this.refs.zoomThumbItems.scrollHeight
    const scrollTarget = thumbClickRatio * this.el.scrollHeight - dimensions().innerHeight / 2

    this.el.scrollTo({
      top: scrollTarget,
      behavior: 'smooth'
    })
  }

  onImageClick = async (event: Event) => {
    this.bindEvents(false)
    if (this.modules.superzoom)
      this.modules.superzoom.flush()

    this.el.classList.add('is-zoomed')

    const img = event.target as HTMLImageElement
    this.modules.superzoom = new ProductSuperZoom(img)
    this.el.appendChild(this.modules.superzoom.el)
    this.modules.superzoom.zoom(event as MouseEvent)
    this.bindEvents(true)
  }

  onClose = async () => {
    scroll.unlock()
    this.el.classList.remove('is-active')
    this.resize()

    if (this.modules.superzoom) {
      if (mqStore.tabletPortrait.get())
        await this.modules.superzoom.unzoom()

      this.modules.superzoom.flush()
      this.modules.superzoom = null

      this.onUnzoomed()
    }
  }

  activate = (position: number): void => {
    scroll.lock()
    this.el.classList.add('is-active')

    const current = this.refs.images[position]
    if (current) this.el.scrollTop = current.offsetTop

    this.resize()
  }

  resize () {
    super.resize()
    const wrapperHeight = this.el.scrollHeight
    const vh = window.innerHeight
    const wrapperThumbHeight = this.refs.zoomThumbItems.scrollHeight
    this.thumbFollowHeight = (wrapperThumbHeight * vh) / wrapperHeight

    this.el.style.setProperty(
      '--zoom-thumb-height',
      `${this.thumbFollowHeight}px`
    )
  }
}

export default ProductZoom
