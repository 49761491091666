import Component from 'navigation/component/Component'
import { ModulesMappping } from 'core/modulesMap'
import InfiniteCarousel from 'components/infinite-carousel/InfiniteCarousel'
import './HomeCarousel.scss'
import mqStore from 'stores/mqStore'
import { bindMethod } from 'helpers/bind'
import anime, { AnimeInstance } from 'animejs'

type HomeCarouselType = {
  refs: {
    tracks: HTMLButtonElement[]
    slider: HTMLElement
  }
  modules : {
    carousel: InfiniteCarousel
  }
}

class HomeCarousel extends Component<HomeCarouselType> {
  private animation:AnimeInstance | null = null
  private switching:boolean = false
  private fraction:number = 0 // fraction of slider to get image width/scroll offset
  private count:number = 0 // number of images

  constructor (el: HTMLElement, options:any) {
    super(el, options)
    this.bindRefs()
  }

  getModulesMap (): ModulesMappping {
    if (!mqStore.tabletPortrait.get()) {
      return {
        carousel: ['.home-carousel__inner', InfiniteCarousel]
      }
    } else { return {} }
  }

  initialized (): void {
    this.bindModules()
    super.initialized()
    if (this.refs.slider) {
      this.count = parseInt(this.refs.slider!.dataset!.count!)
      this.fraction = this.refs.slider.scrollWidth / this.count
    }
  }

  bindEvents (add = true): void {
    const method = bindMethod(add)
    mqStore.tabletPortrait.toggleListener(add, () => {
      this.bindModules()
    })
    this.refs.slider[method]('scroll', this.onScroll)

    if (this.refs.tracks && this.refs.tracks.length) {
      this.refs.tracks.forEach((track: HTMLElement) => {
        track[method]('click', this.onTrackClick)
      })
    }
  }

  onScroll = (e:Event) => {
    if (this.switching) return

    const active = Math.round(this.refs.slider.scrollLeft / this.fraction)
    this.setActiveTrack(active)
  }

  onTrackClick = async (event: Event) => {
    event.preventDefault()
    event.stopPropagation()
    const index = this.refs.tracks.indexOf(event.currentTarget as HTMLButtonElement)
    this.refs.slider.classList.add('switching')
    const options = {
      scrollLeft: this.refs.slider.scrollLeft
    }
    this.switching = true
    if (this.animation) this.animation.pause()
    this.setActiveTrack(index)
    this.animation = anime({
      targets: options,
      scrollLeft: index * this.fraction,
      easing: 'easeOutExpo',
      update: () => {
        this.refs.slider.scrollLeft = options.scrollLeft
      },

      duration: 1000
    })
    await this.animation.finished
    this.refs.slider.classList.remove('switching')
    this.switching = false
  }

  setActiveTrack = (active: number) => {
    this.refs.tracks.forEach((track: HTMLElement, index: number) => {
      if (index === active)
        track.classList.add('active')
      else
        track.classList.remove('active')
    })
  }
}

export default HomeCarousel
