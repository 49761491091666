import Component from 'navigation/component/Component'
import './MarketSelector.scss'
import Form from 'components/form/Form'
import { ModulesMappping } from 'core/modulesMap'
import { bindMethod } from 'helpers/bind'
import store from 'stores'

type MarketSelectorType = {
  refs:{
    buttons:HTMLButtonElement[]
    applyButton:HTMLButtonElement
    language:HTMLInputElement
    country:HTMLInputElement
    close: HTMLButtonElement
  },
  modules:{
    form:Form
  }
}
export class MarketSelector extends Component<MarketSelectorType> {
  constructor (el:HTMLElement, options:any) {
    super(el, options)
    this.bindRefs()
  }

  getModulesMap (): ModulesMappping {
    return {
      form: ['.market-selector__form', Form]
    }
  }

  initialized (): void {
    this.bindModules()
    super.initialized()
  }

  bindEvents = (add = true): void => {
    const method = bindMethod(add)
    window[method]('click', this.onWindowClick)
    this.refs.buttons.forEach(button => {
      button[method]('click', this.onSelect)
    })
    this.refs.applyButton[method]('click', this.onSubmit)
    this.refs.close[method]('click', this.onClose)
    store.popin.toggleListener(add, this.onPopinToggle)
  }

  onClose = () => {
    store.popin.set(null)
  }

  onPopinToggle = (value:string|null) => {
    if (value === 'market-selector')
      this.el.classList.add('market-selector__active')
    else
      this.el.classList.remove('market-selector__active')
  }

  onSelect = (e:Event) => {
    this.el.querySelector('.selected')?.classList.remove('selected')
    const button = e.target as HTMLButtonElement
    const { country, language } = button.dataset
    this.refs.language.value = language!
    this.refs.country.value = country!
    button.classList.add('selected')
  }

  onSubmit = (e:Event) => {
    (this.modules.form.el as HTMLFormElement).submit()
    this.refs.applyButton.classList.add('loading')
  }

  onWindowClick = (e:Event) => {
    // if (!this.el.contains(e.target as Node)) {
    //   console.log('clear')
    //   store.popin.set(null)
    // }
  }
}
