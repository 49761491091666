import Component from 'navigation/component/Component'
import './HomeDiptych.scss'
import homepageStore from 'stores/homepageStore'

class HomeDiptych extends Component {
  constructor(el: HTMLElement) {
    super(el)
    this.bindRefs()
  }

  initialized(): void {
    super.initialized()
    homepageStore.blocksEl.set([...homepageStore.blocksEl.get(), this.el])
  }
}

export default HomeDiptych
