import router from 'core/router'

import './ProductsRelated.scss'
import config from 'core/config'
import { ModulesMappping } from 'core/modulesMap'

import ProductCarousel from '../product-carousel/ProductCarousel'
import ProductCard from '../product-card/ProductCard'

class ProductsRelated extends ProductCarousel {
  getModulesMap (): ModulesMappping {
    return {
      productCard: ['.product-card', ProductCard]
    }
  }

  constructor (el: HTMLElement, options: any) {
    super(el, options)
    this.bindModules()
    this.hydrate()
  }

  hydrate = async () => {
    let productId = this.el.dataset.productId as string
    if (productId.length === 0)
      productId = config.firstProduct
    let url = this.el.dataset.url as string
    url += `&product_id=${productId}`

    const response = await fetch(url)
    const text = await response.text()
    const html = document.createElement('div')

    html.innerHTML = text
    const recommendations = html.querySelector('.' + this.el.className)
    if (!recommendations) return

    this.el.innerHTML = recommendations.innerHTML
    this.el.parentElement?.classList.add('visible')
    router.updatePageLinks()
  }
}

export default ProductsRelated
