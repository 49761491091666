import ProductGrid from 'components/product/product-grid/ProductGrid'
import { ModulesMappping } from 'core/modulesMap'
import InternalPage from 'navigation/pages/InternalPage'

class CollectionInner extends InternalPage {
  constructor (el:HTMLElement) {
    super(el)
  }

  initialized (): void {
    super.initialized()
  }

  bindModules () {
    super.bindModules()
  }

  getModulesMap (): ModulesMappping {
    return {
      ...super.getModulesMap(),
      grid: ['.product-grid', ProductGrid]
    }
  }
}
export default CollectionInner
