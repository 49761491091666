import Component from 'navigation/component/Component'
import { ProductContext } from 'stores/productStore'
import './ProductPopupBar.scss'
import store from 'stores'
import { bindMethod } from 'helpers/bind'

type ProductPopupBarType = {
  modules: {
  }
  refs: {
    popupBarClose: HTMLElement
    popupBarOverlay: HTMLElement
  }
  context: ProductContext
}

class ProductPopupBar extends Component<ProductPopupBarType> {
  private observer: MutationObserver | null = null
  initialized (): void {
    this.bindRefs()
    super.initialized()
  }

  bindEvents (add = true): void {
    if (add) {
      this.observer = new MutationObserver(this.onMutation)
      this.observer.observe(this.el, { attributes: true, subtree: true, attributeFilter: ['class'] })
    } else {
      this.observer?.disconnect()
    }

    store.menu.toggleListener(add, this.close)
    const method = bindMethod(add)
    this.refs.popupBarOverlay[method]('click', this.close)
    this.refs.popupBarClose[method]('click', this.close)
  }

  onMutation = () => {
    const child = this.el.querySelector('.button-drop-up.opened')
    this.el.classList.toggle('has-drop-up', !!child)
  }

  toggle = () => {
    this.el.classList.toggle('opened')
    const enabled = this.el.classList.contains('opened')
    store.popin.set(enabled ? 'product-pop-up-bar' : null)
  }

  close = () => {
    this.el.classList.remove('opened')
    store.popin.set(null)
  }
}

export default ProductPopupBar
