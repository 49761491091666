import Accordion from 'components/accordion/Accordion'
import Button from 'components/button/Button'
import Form from 'components/form/Form'
import MoreButton from 'components/more-button/MoreButton'
import Price from 'components/price/Price'
import ProductCarousel from 'components/product/product-carousel/ProductCarousel'
import Show from 'components/show/Show'
import StickyBottomBar from 'components/sticky-bottom-bar/StickyBottomBar'
import SelectNative from 'components/select-native/SelectNative'
import ButtonDropUp from 'components/button-drop-up/ButtonDropUp'
import Stockists from 'components/stockists/Stockists'
import Footer from 'components/footer/Footer'
import Utils from 'components/utils/Utils'

export interface IComponentClass {
  new (el: HTMLElement, options: any): any
}

export type ModuleMapping = [string, IComponentClass | null]
export type ModulesMappping = Record<string, ModuleMapping>

const modulesMap : ModulesMappping = {
  stickyBottomBar: ['.sticky-bottom-bar', StickyBottomBar],
  button: ['.button', Button],
  moreButton: ['.more-button', MoreButton],
  form: ['form.form', Form],
  footer: ['.footer', Footer],
  price: ['.price', Price],
  accordion: ['.accordion', Accordion],
  productCarousel: ['.product-carousel', ProductCarousel],
  show: ['section.show', Show],
  stockists: ['section.stockists', Stockists],
  utils: ['section.utils', Utils],
  selectNative: ['.select-native', SelectNative],
  buttonDropUp: ['.button-drop-up', ButtonDropUp]
}

export default modulesMap
