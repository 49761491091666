import Component from 'navigation/component/Component'
import './CartItemQuantity.scss'
import { bindMethod } from 'helpers/bind'
import config from 'core/config'
import { query } from 'data/query'
import store from 'stores'
import cart from 'stores/cart'
import Cookies from 'js-cookie'

type CartItemQuantityType = {
  refs: {
    increment: HTMLButtonElement
    decrement: HTMLButtonElement
    quantity: HTMLSpanElement
  }
}
export default class CartItemQuantity extends Component<CartItemQuantityType> {
  private productId: string = ''
  constructor (el: HTMLElement, options: any) {
    super(el, options)
    this.bindRefs()
  }

  initialized (): void {
    super.initialized()
    this.productId = this.refs.decrement.parentElement?.dataset.productId || ''
  }

  bindEvents (add = true): void {
    const method = bindMethod(add)
    this.refs.increment[method]('click', () => this.update(1))
    this.refs.decrement[method]('click', () => this.update(-1))
  }

  async update (delta:number): Promise<void> {
    cart.loading.set(true)
    cart.updateQuantity(this.productId, parseInt(this.refs.quantity.innerText, 10) + delta)
    this.emit('update')
  }
}
