import { each } from 'lodash-es'

import mqSizes from 'styles/libs/_mq.module.scss'

import resize from '../helpers/resize'
import { createStore } from '../helpers/state'

// const prefix = 'mq'
const initialState = {} as Record<string, boolean>
const match = {} as Record<string, number>
each(mqSizes, (size, k) => {
  const key = k.replace('MaxWidth', '')
  const _size = parseInt(size)
  initialState[key] = resize.width() < _size
  match[key] = _size
})

type MQStore = {
  tablet: boolean
  tabletPortrait: boolean
  phone: boolean
  phonePortrait: boolean
}
const mqStore = createStore<MQStore>(initialState as MQStore)

resize.add({
  resize: () => {
    each(mqStore, (state, key) => {
      state.set(resize.width() < match[key])
    })
  }
})

export default mqStore
