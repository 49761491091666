import Component from 'navigation/component/Component'
import './CartStickyBar.scss'
import { ModulesMappping } from 'core/modulesMap'
import Button from 'components/button/Button'
import cart from 'stores/cart'

import ReinsuranceTabs from '../../reinsurance-tabs/ReinsuranceTabs'

type CartStickyBarType = {
  modules: {
    checkoutButton: Button
  }

}

export default class CartStickyBar extends Component<CartStickyBarType> {
  constructor (el:HTMLElement, options:any) {
    super(el, options)
    this.bindRefs()
    this.bindModules()
  }

  getModulesMap (): ModulesMappping {
    return {
      checkoutButton: ['.cart-sticky-bar__checkout', Button],
      reinsuranceTabs: ['.reinsurance-tabs', ReinsuranceTabs]
    }
  }

  hide () {
    this.el.classList.remove('cart-items__active')
  }

  reveal () {
    this.el.classList.add('cart-items__active')
  }

  show () {
    if (!window.location.hash.includes('wishlist'))
      this.reveal()
  }
}
