import { compact, defer } from 'lodash-es'
import { TinyEmitter } from 'tiny-emitter'
import router, { getRoot, getUri } from 'core/router'
import store from 'stores'
import { IPageClass, IPage } from 'navigation/pages/Page'
import promise from 'helpers/promise'
import pageMap from 'core/pageMap'

type PageManagerState = {
  transitioning: boolean,
  loading: boolean,
  init: boolean,
  previous?: string,
  next?: string,
}

export type RequestOptions = {
  method?: string,
  body?: any,
  rewriteRoute?: (pathName: string, overwrite?: boolean, absolute?: boolean) => void,
}

class PageManager extends TinyEmitter {
  main : boolean = true
  disabled : boolean = false
  loadBeforeHide : boolean
  crossTransition : boolean

  currentPage?: IPage
  previousPage?: IPage

  nextPageInfo?: {
    title: string
    bodyClassName: string
  }

  xhr?: XMLHttpRequest
  pathName?: string
  newPathName?: string
  container : HTMLElement
  pageSelector : string
  defaultPageClass : IPageClass

  parameters: any

  state = {
    transitioning: false,
    loading: false,
    init: false
  } as PageManagerState

  constructor (
    container: HTMLElement,
    pageSelector: string,
    defaultPageClass: IPageClass,
    parameters: any = {},
    {
      loadBeforeHide = true, // ⚠ Should be true if you want to use virtual page manager
      crossTransition = false
    } = {}
  ) {
    super()

    // Page Parameters
    this.parameters = parameters

    // Transition Parameters
    this.loadBeforeHide = loadBeforeHide
    this.crossTransition = crossTransition

    // Manager Parameters
    this.container = container
    this.defaultPageClass = defaultPageClass
    this.pageSelector = pageSelector

    this.initializeRoutes()
  }

  async initializePage () {
    if (this.main) {
      this.extractPageInfo(document)
      this.pathName = getUri()
      if (this.nextPageInfo && this.nextPageInfo.bodyClassName.indexOf('error404') > -1) this.pathName = '404'
    }

    const el = this.extractPage(this.container)
    if (!el) return

    const pageClass = await this.getPageClass(el)
    this.currentPage = this.createPage(el, pageClass)

    const prev = this.state.previous

    if (!this.currentPage) return

    router.updatePageLinks()

    await this.currentPage.askPreload(prev)

    this.state.init = true
    this.emit('init')

    await promise.wait()
    this.emit('show', this.currentPage)

    await this.currentPage.askShow(prev)
    this.currentPage.transitionComplete()
  }

  initializeRoutes () {
    router.on('**', (event) => {
      const pathName = event ? compact([event.url, event.queryString]).join('?') : ''
      this.onRouteUpdate('/' + pathName)
    })
    defer(() => router.resolve())
  }

  virtual (pathName: string, requestOptions: RequestOptions) {
    this.emit('virtual', pathName)
    this.rewriteRoute(pathName, false)
    this.onRouteUpdate(pathName, requestOptions)
  }

  rewriteRoute (pathName: string, overwrite = true) {
    if (overwrite) {
      router.navigate(pathName, { callHandler: false, callHooks: false })
    } else {
      if (pathName === this.newPathName) return
      this.disabled = true
      router.navigate(pathName)
      this.disabled = false
    }
  }

  inject (pathName: string, xhr: XMLHttpRequest, requestOptions: RequestOptions = {}) {
    const cb = () => {
      this.rewriteRoute(pathName, false)
      this.pageLoaded(pathName, xhr, requestOptions)
    }

    if (this.state.transitioning || this.state.loading) this.once('shown', cb)
    else cb()
  }

  navigateTo (pathName: string) {
    router.navigate(pathName)
  }

  onRouteUpdate (pathName: string, requestOptions: RequestOptions = {}) {
    if (this.disabled) return false
    if (!this.currentPage && this.extractPage(this.container) && !this.previousPage) {
      this.initializePage()
      return true
    }

    pathName = pathName.split('#')[0]

    // if (this.state.transitioning || this.state.loading) {
    //   this.rewriteRoute(this.newPathName as string)
    //   this.waitingForPage = pathName
    //   return
    // }

    this.newPathName = pathName
    this.emit('navigate', pathName)

    if (!this.loadBeforeHide) {
      if (this.currentPage) this.previousPage = this.currentPage
      if (this.previousPage) this.hidePage()
    }

    this.emit('loading')
    this.load(pathName, requestOptions)
    return true
  }

  load (pathName: string, { method = 'GET', body, ...options }: RequestOptions = {}) {
    if (this.xhr) {
      this.xhr.onload = this.xhr.onerror = null
      this.xhr.abort()
    }

    this.state.loading = true

    this.xhr = new XMLHttpRequest()
    this.xhr.withCredentials = true
    this.xhr.open(method, pathName, true)
    this.xhr.setRequestHeader('X-Fursac-Ajax', 'true')

    this.xhr.responseType = 'document'
    this.xhr.onload = (event: ProgressEvent) => {
      this.pageLoaded(pathName, event.target as XMLHttpRequest, options)
    }
    this.xhr.send(body)
  }

  triggerInternalRouting (pathName: string, xhr: XMLHttpRequest, requestOptions: RequestOptions) {
    requestOptions.rewriteRoute = this.rewriteRoute.bind(this)
    this.state.loading = false
    this.currentPage?.internalRouting?.(pathName, xhr, requestOptions)
  }

  cancelTransition () {
    this.rewriteRoute(this.pathName as string, true)
    this.state.loading = false
  }

  async pageLoaded (pathName: string, xhr: XMLHttpRequest, requestOptions: RequestOptions) {
    const page = xhr.response as Document
    const el = this.extractPageFromXHR(page.body)

    // DETECT ERROR
    if (!el) {
      document.documentElement.innerHTML = page.documentElement.innerHTML // Unhandled errors
      return false
    }

    // INJECT IN ANOTHER ROUTER
    if (el.hasAttribute('data-router')) {
      const routerKey = el.getAttribute('data-router')
      el.removeAttribute('data-router')
      const router = store.routers.get()[routerKey as string]

      if (router && router !== this) {
        router.inject(pathName, xhr, requestOptions)
        this.emit('loaded')
        this.cancelTransition()
        return false
      }
    }

    const PageClass = await this.getPageClass(el)

    const current = this.currentPage

    // INTERNAL ROUTING
    if (
      current &&
      current.pageName() === PageClass.pageName &&
      current.internalRouting &&
      current.shouldRouteInternally?.(el, pathName)
    ) {
      this.triggerInternalRouting(pathName, xhr, requestOptions)
      return false
    }

    this.pathName = xhr.responseURL
    this.state.next = PageClass.pageName

    if (xhr.responseURL !== pathName && ~xhr.responseURL.indexOf(getRoot())) {
      const newPath = xhr.responseURL.replace(getRoot(), '')
      if (requestOptions.rewriteRoute) requestOptions.rewriteRoute(newPath, false, false)
      else this.rewriteRoute(newPath, false)
    }

    this.emit('loaded', page)
    this.extractPageInfo(page)
    this.preparePage(el, PageClass)

    xhr.onload = null
    return true
  }

  extractPageInfo (page: Document) {
    this.nextPageInfo = {
      title: page.title,
      bodyClassName: page.body.className
    }
  }

  async preparePage (el: HTMLElement, PageClass: IPageClass) {
    if (this.loadBeforeHide && this.currentPage)
      this.previousPage = this.currentPage

    const page = this.createPage(el, PageClass)
    this.currentPage = page

    const previousPage = this.previousPage && this.previousPage.pageName()

    this.container.appendChild(page.el)

    await page.askPreload(previousPage)

    this.state.loading = false
    this.state.transitioning = true

    if (this.previousPage) {
      if (this.previousPage?.state.hidden) this.pageHidden()
      else if (this.loadBeforeHide) this.hidePage()

      if (this.crossTransition) this.showPage()
    } else {
      this.showPage()
    }
  }

  async showPage () {
    if (!this.currentPage) return

    const previousPage = this.state.previous

    if (this.main) {
      document.body.className = this.nextPageInfo?.bodyClassName || ''
      if (this.nextPageInfo?.title) document.title = this.nextPageInfo?.title || ''
    }

    await promise.wait()
    router.updatePageLinks()
    this.emit('show', this.currentPage)
    await this.currentPage.askShow(previousPage)
    this.pageShown()
  }

  transitionComplete () {
    if (!this.state.transitioning) return
    this.state.transitioning = false
    this.emit('shown', this.currentPage)
    this.currentPage?.transitionComplete()
  }

  pageShown = () => {
    if (this.crossTransition && this.previousPage) this.removePage()
    this.transitionComplete()

    // if (this.waitingForPage) {
    //   this.navigateTo(this.waitingForPage)
    //   this.waitingForPage = null
    // }
  }

  async hidePage () {
    if (!this.previousPage) return

    const nextPage = this.state.next
    this.state.previous = this.previousPage.pageName()
    this.emit('hide', this.previousPage)
    await this.previousPage.askHide(nextPage)
    this.pageHidden()
  }

  pageHidden = () => {
    if (!this.loadBeforeHide && this.state.loading) return

    if (this.crossTransition) {
      if (!this.currentPage || this.currentPage.state.shown) {
        this.removePage()
        this.transitionComplete()
      }
    } else {
      this.removePage()
      if (this.loadBeforeHide || this.currentPage) this.showPage()
    }
  }

  removePage () {
    if (!this.previousPage) return
    this.container.removeChild(this.previousPage.el)
    this.previousPage.flush()
    this.previousPage = undefined
  }

  async getPageClass (el: HTMLElement) {
    if (!el) return this.defaultPageClass
    const pageClassName = el.getAttribute('data-template') || ''
    // const pageMap = require('core/pageMap').default as Record<string, IPageClass>
    // const pageMap = (await import('core/pageMap'))?.default as Record<string, IPageClass>
    return pageMap[pageClassName] || this.defaultPageClass
  }

  extractPageFromXHR (el: HTMLElement) {
    return this.extractPage(el)
  }

  extractPage (el: HTMLElement) {
    return el.querySelector(this.pageSelector) as HTMLElement
  }

  createPage (el: HTMLElement, PageClass: IPageClass) {
    const page = new PageClass(el, this.parameters, this)
    return page
  }

  resize = () => {
    if (this.currentPage) this.currentPage.resize()
    if (this.previousPage) this.previousPage.resize()
  }

  flush () {
    if (this.currentPage) this.currentPage.flush()
    if (this.previousPage) this.previousPage.flush()
  }
}

export default PageManager
