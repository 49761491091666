import Component from 'navigation/component/Component'
import './ReinsuranceTabs.scss'
import { ModulesMappping } from 'core/modulesMap'
import ButtonDropUp from 'components/button-drop-up/ButtonDropUp'

export default class ReinsuranceTabs extends Component {
  constructor (el:HTMLElement, options:any) {
    super(el, options)
    this.bindRefs()
    this.bindModules()
  }

  getModulesMap (): ModulesMappping {
    return {
      buttonDropUp: ['.button-drop-up', ButtonDropUp]
    }
  }

  initialized (): void {
    super.initialized()
  }
}
