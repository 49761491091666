import Component from 'navigation/component/Component'
import './CartItem.scss'
import { ProductSizes } from 'components/product/product-sizes/ProductSizes'
import { ModulesMappping } from 'core/modulesMap'
import SelectNative from 'components/select-native/SelectNative'
import cart from 'stores/cart'
import { bindEmitterMethod } from 'helpers/bind'

import CartItemQuantity from '../cart-item-quantity/CartItemQuantity'

type CartItemType = {
  refs: {
    sizeSelect: HTMLSelectElement
  }
  modules: {
    productSizes: ProductSizes
    productSelect: SelectNative
    cartItemQuantity: CartItemQuantity
  }
}
export default class CartItem extends Component<CartItemType> {
  private productId: string = ''
  private quantity: number = 0

  getModulesMap (): ModulesMappping {
    return {
      productSizes: ['.product-sizes', ProductSizes],
      productSelect: ['.cart-item__size-select', SelectNative],
      cartItemQuantity: ['.cart-item__quantity', CartItemQuantity]
    }
  }

  initialized (): void {
    this.bindModules()
    this.productId = this.el.dataset.productId || ''
    this.quantity = parseInt(this.el.dataset.quantity || '0', 10)
    super.initialized()
  }

  bindEvents (add = true): void {
    const emitterMethod = bindEmitterMethod(add)
    if (this.modules.productSizes) this.modules.productSizes[emitterMethod]('change', this.onSizeChange)
    if (this.modules.productSelect) this.modules.productSelect[emitterMethod]('change', this.onSizeChange)
    if (this.modules.cartItemQuantity) {
      this.modules.cartItemQuantity[emitterMethod]('update', () => {
        cart.loading.set(true)
      })
    }
  }

  onSizeChange = async (variantId:string) => {
    cart.loading.set(true)
    await cart.updateVariant(this.productId, variantId, this.quantity)
  }
}
