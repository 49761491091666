import Component from 'navigation/component/Component'
import './Utils.scss'
import { bindMethod } from 'helpers/bind'

type UtilsType = {
  refs: {
    utilsButtons: HTMLButtonElement[];
    utilsElements: HTMLElement[];
    accordionElements: HTMLElement[];
  };
};

class Utils extends Component<UtilsType> {
  // private autoScrolling: boolean = false

  constructor (el: HTMLElement) {
    super(el)
    this.bindRefs()
  }

  initialized (): void {
    super.initialized()
    this.handleScroll()
  }

  bindEvents (add: boolean): void {
    const method = bindMethod(add)

    if (this.refs.utilsButtons) {
      this.refs.utilsButtons.forEach(button => {
        button[method]('click', this.handleTitleButtonClick)
      })
    }

    if (this.refs.accordionElements) {
      this.refs.accordionElements.forEach(element => {
        element[method]('click', this.handleAccordionClick)
      })
    }

    window[method]('scroll', this.handleScroll)
  }

  handleTitleButtonClick = (e: Event) => {
    const button = e.currentTarget as HTMLButtonElement
    const id = button.dataset.block as string

    const targetElement = this.refs.utilsElements.find(element => element.dataset.block === id)

    if (!targetElement) return
    const elPos = targetElement.offsetTop

    window.scrollTo({
      top: elPos,
      behavior: 'smooth'
    })
  }

  handleScroll = () => {
    const top = document.scrollingElement?.scrollTop || 0

    const current = this.refs.utilsElements.find(element => {
      return top <= element.offsetTop + (element.offsetHeight / 2)
    })?.dataset?.block

    if (!this.refs.utilsButtons) return

    this.refs.utilsButtons.forEach((element, index) => {
      element.classList.toggle('current', element.dataset?.block === current)
    })
  }

  handleAccordionClick = (e: Event) => {
    const button = e.currentTarget as HTMLButtonElement
    const id = button.dataset.id as string

    const targetElement = this.refs.accordionElements.find(element => element.dataset.id === id)

    if (!targetElement) return

    targetElement.classList.toggle('active')
  }
}

export default Utils
