import ProductListStickyBar from 'components/product/product-list-sticky-bar/ProductListStickyBar'
import { ModulesMappping } from 'core/modulesMap'
import InternalRouter from 'navigation/pages/InternalRouter'
import { CollectionContext, CollectionGridTypes, createCollectionContext } from 'stores/collectionStore'
import Filters from 'components/filters/Filters'
import { bindEmitterMethod } from 'helpers/bind'
import ProductGrid from 'components/product/product-grid/ProductGrid'
import store from 'stores'
import Accordion from 'components/accordion/Accordion'

import CollectionInner from './CollectionInner'

type CollectionType = {
  modules : {
    filters : Filters,
    stickyBar:ProductListStickyBar
    productGrid: ProductGrid
    accordions: Accordion
  },
  context : CollectionContext
}

class Collection extends InternalRouter<CollectionType> {
  collectionId: string

  getModulesMap (): ModulesMappping {
    return {
      filters: ['.filters-dropdown', Filters],
      stickyBar: ['.product-list-sticky-bar', ProductListStickyBar]
    }
  }

  getDefaultPageClass () {
    return CollectionInner
  }

  constructor (...args: ConstructorParameters<typeof InternalRouter>) {
    super(...args)
    this.collectionId = this.el.getAttribute('data-collection') || ''
    this.context = createCollectionContext({
      filterOpened: false,
      view: null
    })
  }

  initialized (): void {
    store.view.set(window.location.hash.slice(1) as CollectionGridTypes || (this.el.getAttribute('data-default-view') || 'grid') as CollectionGridTypes)
    super.initialized()
  }

  bindEvents (add: boolean): void {
    const emitterMethod = bindEmitterMethod(add)
    this.modules.stickyBar[emitterMethod]('switchView', this.switchView)
    this.modules.filters[emitterMethod]('updateFilters', this.onUpdateFilters)
  }

  shouldRouteInternally (el: HTMLElement, pathName: string): boolean {
    return el?.getAttribute('data-collection') === this.collectionId
  }

  async internalRouting (...args: Parameters<InternalRouter['internalRouting']>) {
    super.internalRouting(...args)
  }

  switchView = (view: 'grid' | 'row' | 'story') => {
    if (view === 'story') return
    this.context.view.set(view)
  }

  onUpdateFilters = (value:number) => {
    this.modules.stickyBar.updateFilters(value)
  }
}

Collection.pageName = 'collection'

export default Collection
