/* global process, RequestCredentials  */
export const isDev = process.env.NODE_ENV === 'development'

type QueryProps = {
  body?: { [key: string]: any },
  bearer?: string | void,
  method?: string | void,
  headers?: { [key: string]: string | void },
  credentials?: RequestCredentials | undefined,
  accept?: string,
  json?: boolean
  text?: boolean
}

type Params = {
  [key: string]: any
}

const query = (url: string, {
  body,
  method,
  headers = {},
  credentials = 'include',
  accept = 'application/json',
  text = false,
  json = true
}: QueryProps = {}) => {
  const _headers: { [key: string]: string } = { Accept: accept, ...headers }
  if (body && !_headers['Content-Type']) _headers['Content-Type'] = 'application/json'
  return fetch(url, {
    method: method || (body ? 'POST' : 'GET'),
    headers: _headers,
    credentials,
    body: body && JSON.stringify(body)
  })
    .then(response => text ? response.text() : json ? response.json() : response.blob())
    .then((response) => {
      if (json && response && response.error) return Promise.reject(response)
      if (json && response && response.message) return Promise.reject(response)
      return response
    })
}

export { query }
