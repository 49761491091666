import Component from 'navigation/component/Component'
import './CartSuggestions.scss'
export default class CartSuggestions extends Component {
  constructor (el:HTMLElement, options:any) {
    super(el, options)
  }

  hide () {
    this.el.classList.remove('cart-suggestions__active')
  }

  reveal () {
    this.el.classList.add('cart-suggestions__active')
  }

  show () {
    if (!window.location.hash.includes('wishlist'))
      this.reveal()
  }
}
