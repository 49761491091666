import Navigo from 'navigo'
import Config from 'core/config'
import store from 'stores/store'

const buildRoot = (lang: string) => '/'
// const buildRoot = (lang: string) => [Config.shopUrl, lang].join('/')

const router = new Navigo(buildRoot(Config.locale), {})

if ('scrollRestoration' in window.history)
  window.history.scrollRestoration = 'manual'

const history = [] as string[]

router.hooks({
  before: (resolve) => {
    const [path, hash] = getUri().split('#')
    const [currentPath, currentHash] = (getCurrentPage() || '').split('#')
    const isSamePage = currentPath === path && hash !== currentHash
    history.push(getUri())

    if (isSamePage) return resolve(false)

    if (history.length > 1) {
      // store.search.set(null)
      // store.panel.set(null)
      // store.menu.set(null)
    }

    store.path.set(getUri().replace(getRoot(), ''))

    const mainRouter = store.routers.get().main

    if (mainRouter.state.transitioning || mainRouter.state.loading)
      return resolve(false)
    resolve()
  },
  after: () => {
    // history.push(router.path())
  }
})
export const getRoot = () => window.location.origin
export const getPath = () => window.location.href
export const getUri = () => window.location.pathname + window.location.search

export const getHistory = () => history
export const getLastPage = () => history.length > 1 ? history[history.length - 2] : false
export const getCurrentPage = () => history.length > 0 ? history[history.length - 1] : false
export const lastResolvedRoute = () => router.lastResolved()?.[0]
export const updateLastRoute = (route: string) => {
  const lastResolved = lastResolvedRoute()
  if (lastResolved) lastResolved.url = route
}

// export const updateLang = (lang: string) => {
//   if (lang === Config.lang) return
//   Config.lang = lang
//   getRoot() = buildRoot(Config.lang)

//   const lastResolved = router.lastResolved()
//   if (lastResolved) lastResolved.url = ''
// }

// router._findLinks = () => [].slice.call(document.querySelectorAll('[data-navigo], .data-navigo'))

router.getLinkPath = (link: HTMLAnchorElement) => {
  return link.getAttribute('href')?.replace(getRoot(), '') || ''
}

// router.updatePageLinks = () => {
//   if (typeof document === 'undefined') return

//   // tag.updatePageEvents()

//   router._findLinks().forEach(link => {
//     if (!link.hasListenerAttached) {
//       link.addEventListener('click', (e) => {
//         if ((e.ctrlKey || e.metaKey) && e.currentTarget.tagName.toLowerCase() === 'a') return false
//         // if ((e.ctrlKey || e.metaKey) && e.target.tagName.toLowerCase() === 'a') return false
//         const location = router.getLinkPath(link)

//         if (!router._destroyed) {
//           e.preventDefault()
//           router.navigate(location.replace(/\/+$/, '').replace(/^\/+/, '/'))
//         }
//       })
//       link.hasListenerAttached = true
//     }
//   })
// }

export default router
