import MainPage from './MainPage'

export default class InternalPage extends MainPage {
  constructor (el: HTMLElement) {
    super(el)
    el.classList.add('internal-page')
  }

  /* SHOW */
  async askPreload (previousPage?:string) {
    if (previousPage) this.el.style.opacity = '0'
    return super.askPreload(previousPage)
  }

  preload (previousPage?:string) {
    if (previousPage) return super.preload(...arguments)
    else return Promise.resolve()
  }

  getTranslateY (hide = false) {
    return hide ? '-40vh' : '40vh'
  }

  show (previousPage?: string) {
    if (!previousPage) {
      this.el.style.opacity = '1'
      this.showChildren()
      return Promise.resolve()
    } else {
      return super.show(previousPage)
    }
  }
}
