import Component from 'navigation/component/Component'
import './Accordion.scss'
import { bindMethod } from 'helpers/bind'
import { ModulesMappping } from 'core/modulesMap'
import MoreButton from 'components/more-button/MoreButton'

type AccordionType = {
  modules:{
    button: MoreButton
  }
}

class Accordion extends Component<AccordionType> {
  initialized (): void {
    this.bindRefs()
    this.bindModules()
    super.initialized()
  }

  getModulesMap (): ModulesMappping {
    return {
      button: ['.button.more-button', MoreButton]
    }
  }

  bindEvents (add = true) {
    const method = bindMethod(add)

    this.el[method]('click', this.toggle)
  }

  toggle = () => {
    this.el.classList.toggle('opened')
    this.modules.button.el.classList.toggle('open')
  }
}
export default Accordion
