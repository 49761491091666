import { cloneDeep } from 'lodash-es'

type Config = {
  shopUrl: string
  locale: string
  shop:{
    country:{
      name:string
      code:string
      currency:{
        name:string
        code:string
      }
    }
  },
  api: {
    cart: {
      get:string
      add: string
      change: string
      update: string
      url: string
    }
    predictiveSearch: {
      url:string
    }
  },
  assets: {
    favicon: string
  }
  firstProduct:string
  variantStrings:{
    cart:{
      added:string
      checkout:string
    }
    shipping:{
      calculated_at_checkout:string
    },
    filters:{
      one:string
      many:string
    }
  }
}

const config = cloneDeep(Object.assign({}, (window as any).config)) as Config

export default config
