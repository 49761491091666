import { ModulesMappping } from 'core/modulesMap'
import Component from 'navigation/component/Component'
import Form from 'components/form/Form'
import { CollectionContext } from 'stores/collectionStore'
import { bindEmitterMethod, bindMethod } from 'helpers/bind'
import { debounce, defer } from 'lodash-es'
import store from 'stores'
import './Filters.scss'

type FiltersType = {
    modules : {
        form : Form
    },
    refs: {
      buttonContent: HTMLElement
      productLabel: HTMLElement
      filtersToggle: HTMLElement
      filtersDropdownCount: HTMLElement
      filtersDropdownLabel: HTMLElement
      submitForm: HTMLButtonElement
    }
    context : CollectionContext
  }

class Filters extends Component<FiltersType> {
  initialized (): void {
    this.bindRefs()
    this.bindModules()
    super.initialized()
    defer(() => {
      this.updateFiltersCount(true)
    })
  }

  getModulesMap (): ModulesMappping {
    return {
      form: ['.filters-form', Form]
    }
  }

  bindEvents (add = true): void {
    const method = bindMethod(add)
    const emitterMethod = bindEmitterMethod(add)
    this.modules.form[emitterMethod]('input', this.onUpdate)
    this.modules.form[emitterMethod]('reset', () => this.reset())
    this.modules.form[emitterMethod]('submit', () => {
      this.updateFiltersCount(true)
      store.filtersOpened.set(false)
      store.popin.set(null)
    })
    this.refs.filtersToggle[method]('click', () => {
      store.filtersOpened.set(false)
      store.popin.set(null)
    })

    store.filtersOpened.listen((value) => {
      this.toggle(value)
    })
  }

  toggle = (value:boolean) => {
    this.refs.filtersToggle.classList.toggle('opened', value)
    this.refs.filtersToggle.parentElement?.parentElement?.classList.toggle('visible', value)
  }

  onUpdate = debounce(async () => {
    this.refs.submitForm.classList.toggle('loading')
    this.updateFiltersCount(false)
    const request = await fetch(window.location.pathname + this.modules.form.getFormValuesAsURL() + '&section_id=filter-count')
    this.refs.buttonContent.innerHTML = await request.text()
    this.refs.submitForm.classList.toggle('loading')
  }, 500)

  updateFiltersCount = (emit:boolean) => {
    let inc = 0
    const w = window as any

    const data = this.modules.form.getFormValues()
    // delete query fitler from count
    delete data.q
    Object.keys(data).forEach(key => {
      inc += data[key].length
    })
    if (inc > 0) {
      this.refs.filtersDropdownCount.classList.add('visible')
      this.refs.filtersDropdownCount.innerHTML = inc.toString()
      this.refs.filtersDropdownLabel.innerHTML = inc === 1 ? w.variantStrings.filters.one : w.variantStrings.filters.many
    } else {
      this.refs.filtersDropdownCount.classList.remove('visible')
      this.refs.filtersDropdownLabel.innerHTML = w.variantStrings.filters.one
    }
    if (emit)
      this.emit('updateFilters', inc)
  }

  reset () {
    this.modules.form?.setSubmitted(false)
    this.modules.form.checkboxes.forEach(checkbox => {
      checkbox.checked = false
    })
    const form = this.modules.form.el as HTMLFormElement
    form.submit()
  }
}

export default Filters
