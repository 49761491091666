import { bindMethod } from 'helpers/bind'
import './ButtonDropUp.scss'
import Component from 'navigation/component/Component'

type ButtonDropUpType = {
  refs:{
    toggle:HTMLButtonElement
    dropdown: HTMLElement
  }
}
class ButtonDropUp extends Component<ButtonDropUpType> {
  constructor (el:HTMLElement, options:any) {
    super(el, options)
    this.bindRefs()
  }

  bindEvents (add = true): void {
    const method = bindMethod(add)
    this.refs.toggle[method]('click', this.toggle)
    window[method]('click', this.close)
  }

  toggle = () => {
    this.el.classList.toggle('opened')
    this.emit('toggle', this.refs.dropdown.dataset.id)
  }

  close = (e:Event) => {
    if (!this.el.contains(e.target as Node))
      this.el.classList.remove('opened')
  }
}

export default ButtonDropUp
