import Component from 'navigation/component/Component'
import './Stockists.scss'
import { bindMethod } from 'helpers/bind'

type StockistsType = {
  refs: {
    countryButtons: HTMLButtonElement[]
    countryElements: HTMLElement[]
  }
}

class Stockists extends Component<StockistsType> {
  constructor (el: HTMLElement) {
    super(el)
    this.bindRefs()
  }

  initialized (): void {
    super.initialized()
    this.updateStatus('all')
  }

  bindEvents (add: boolean): void {
    const method = bindMethod(add)
    this.refs.countryButtons.forEach(button => {
      button[method]('click', this.handleCountryButtonClick)
    })
  }

  handleCountryButtonClick = (e: Event) => {
    const button = e.currentTarget as HTMLButtonElement
    const id = button.dataset.country as string

    this.updateStatus(id)
  }

  updateStatus = (country: string) => {
    this.refs.countryButtons.forEach(button => {
      button.classList.toggle('current', button.dataset.country === country)
    })

    if (country === 'all') {
      this.refs.countryElements.forEach(element => {
        element.classList.remove('hidden')
        element.classList.remove('current')
      })
    } else {
      this.refs.countryElements.forEach(element => {
        element.classList.toggle('hidden', element.dataset.country !== country)
        element.classList.toggle('current', element.dataset.country === country)
      })
    }
  }
}

export default Stockists
