import Component from 'navigation/component/Component'
import './MoreButton.scss'
import { bindMethod } from 'helpers/bind'

type MoreButtonType = {
  refs: {
    button: HTMLButtonElement
    buttonContent: HTMLDivElement,
  }
}

class MoreButton extends Component<MoreButtonType> {
  constructor (el: HTMLElement) {
    super(el)
    this.bindRefs()
  }

  bindEvents (add = true) {
    if (!this.refs.buttonContent) return
    const method = bindMethod(add)
    this.el[method]('click', this.onClick)
  }

  onClick = (event: Event) => {
    this.el.classList.toggle('opened')
  }
}

export default MoreButton
