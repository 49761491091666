import { CollectionActions, createCollectionAction } from 'actions/collectionAction'
import { StoreType, createStore } from 'helpers/state'

export type CollectionState = {
  filterOpened: boolean
  view:'grid' | 'row' | 'story' | null
}
export type CollectionGridTypes = 'grid' | 'row' | 'story'

export type CollectionStore = StoreType<CollectionState>
export type CollectionContext = CollectionStore & CollectionActions

const createCollectionStore = (collection: CollectionState) => createStore<CollectionState>({
  filterOpened: !!collection.filterOpened || false,
  view: null
})

const createCollectionContext = (collection: CollectionState) : CollectionContext => {
  const store = createCollectionStore(collection)
  const actions = createCollectionAction(store)
  return { ...store, ...actions }
}

export { createCollectionContext }
