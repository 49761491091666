import { createStore } from 'helpers/state'
import PageManager from 'navigation/page-manager/PageManager'

import { CollectionGridTypes } from './collectionStore'

export type ToasterType = {
  title: string
  link?: {
    href: string
    label: string
  }
  externalLink?: {
    href: string
    label: string
  }
  image?: string
} | null

type BaseStore = {
  lang: string
  path: string | null
  loading: boolean

  error: string | null
  toaster: ToasterType

  menu: string | boolean | null
  panel: string | null
  popin: string | null

  scrollDown: boolean

  blurredOverlay: boolean

  filtersOpened:boolean
  view: CollectionGridTypes

  routers : Record<string, PageManager>
}

const store = createStore<BaseStore>({
  lang: 'fr',
  path: null,
  loading: false,
  scrollDown: false,

  error: null,
  toaster: null,

  menu: null,
  popin: null,
  panel: null,
  view: 'grid',

  filtersOpened: false,
  blurredOverlay: false,
  routers: {}
})

export default store
