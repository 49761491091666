import * as yup from 'yup'
import config from 'core/config'

const codeRegexp = /^[\w]{2,}$/
const phoneRegexp = /^[0-9- _()]+$/
const expirationRegexp = /^((0[1-9])|(1[0-2]))\/[0-9]{2}$/
const nameRegexp = /^[A-z\u00C0-\u00ff\s'\-()]*$/
const latinRegexp = /^[0-9A-z\u00C0-\u00ff\s'.,-/#!$%^&*;:{}=\-_`~()]*$/
const passwordRegexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])(?=.{8,})/
const { errors = {} } = config as any

yup.setLocale({
  mixed: {
    default: errors.invalid,
    notType: errors.invalid,
    required: errors.required
  },
  number: {
    min: errors.invalid,
    max: errors.invalid
  },
  string: {
    email: errors.email,
    min: errors.invalid,
    max: errors.invalid,
    matches: errors.invalid
  }
})

const validators = {
  address: yup.string().matches(latinRegexp, errors.latin),
  name: yup.string().matches(nameRegexp, errors.latin),
  email: yup.string().email(),
  expirationcard: yup.string().matches(expirationRegexp),
  code: yup.string().matches(codeRegexp),
  password: yup.string().matches(passwordRegexp, errors.password),

  // day: yup.lazy((v) => (v === '' ? yup.string() : )),
  // month: yup.lazy((v) => (v === '' ? yup.string() : yup.number().min(1).max(12))),
  // year: yup.lazy((v) => (v === '' ? yup.string() : yup.number().min(1900).max(2030))),

  city: yup.string().matches(latinRegexp, errors.latin),
  street: yup.string().matches(latinRegexp, errors.latin),
  string: yup.string(),
  phone: yup.string().min(5).matches(phoneRegexp),

  required: (v: yup.AnyObject) => (v || yup.string()).required(),
  bool: (() => {
    const bool = yup.bool() as yup.AnyObject
    bool.required = () => bool.oneOf([true])
    return bool
  })()
} as Record<string, yup.AnyObject>

validators.passwordConfirm = validators.password.oneOf([yup.ref('password'), yup.ref('new-password'), null], errors.confirmPassword)

validators.day = yup.mixed().when(['$month', '$year'], {
  is: (month: number, year: number) => (!!month || !!year),
  then: () => yup.number().min(1).max(31)
})

validators.month = yup.mixed().when(['$day', '$year'], {
  is: (day: number, year: number) => (!!day || !!year),
  then: () => yup.number().min(1).max(12)
})

validators.year = yup.mixed().when(['$month', '$day'], {
  is: (month:number, day:number) => (!!month || !!day),
  then: () => yup.number().min(1900).max(2030)
})

export default validators
