import Component from 'navigation/component/Component'
import { defer, find, findIndex } from 'lodash-es'
import browser from 'helpers/browser'
import { bindMethod } from 'helpers/bind'
import scroll from 'core/scroll'
import math from 'helpers/math'
import router from 'core/router'
import { dimensions } from 'helpers/resize'
import './StickyBottomBar.scss'
import store from 'stores'

type BlockReference = {
  wrapper: HTMLElement,
  bar: HTMLElement,
  score: number,
}

type StickyBottomBarType = {
  refs: {
    bottomBarPrev?: HTMLElement
    bottomBarNext?: HTMLElement
    bottomBarButton?: HTMLElement
  }
}

const isFirst = (i:number) => {
  return i === 0
}
const isLast = (i:number, increment:number, limit:number) => i + increment > limit
const attributeMethod = (bool:boolean) => bool ? 'setAttribute' : 'removeAttribute'

class StickyBottomBar extends Component<StickyBottomBarType> {
  private current:BlockReference[] = []
  private io: IntersectionObserver = null!
  private mo: MutationObserver = null!
  private blocks: BlockReference[] = []
  private className = '.sticky-bottom-bar'
  private parentNode: HTMLElement | null = null

  constructor (el: HTMLElement) {
    super(el)
    this.bindRefs()
  }

  initialized (): void {
    super.initialized()

    this.parentNode = browser.getParent(this.el, '.page__inner') as HTMLElement
    if (!this.parent) return

    const bars = Array.from(this.parentNode.querySelectorAll(this.className))
    const index = bars.indexOf(this.el)

    if (index > 0) return

    if (bars.length === 1) {
      if (this.refs.bottomBarNext) this.refs.bottomBarNext.style.display = 'none'
      if (this.refs.bottomBarPrev) this.refs.bottomBarPrev.style.display = 'none'
    }

    this.el = this.el.cloneNode(true) as HTMLElement
    this.el.setAttribute('data-main-bar', 'true')
    this.parentNode.appendChild(this.el)

    this.io = new IntersectionObserver(this.onIntersectsUpdate, {
      threshold: Array(10).fill(true).map((_, i) => i / 10),
      rootMargin: '-50% 0px 0px 0px'
    })

    this.mo = new MutationObserver(this.onMutations)
    this.mo.observe(this.parentNode, {
      childList: true,
      subtree: true
    })

    defer(() => {
      this.updateBlocks()
    })
  }

  updateBlocks = () => {
    if (!this.parentNode) return
    const bars = Array.from(this.parentNode.querySelectorAll(this.className))

    bars.forEach(bar => {
      if (bar === this.el) return
      let block = find(this.blocks, block => block.wrapper === bar.parentElement)
      if (block) return
      block = this.getBlock(bar)
      this.blocks.push(block)
      this.io.observe(block.wrapper)
    })
  }

  getBlock = (bar: Element) => {
    return {
      wrapper: bar?.parentElement as HTMLElement,
      bar: bar.cloneNode(true) as HTMLElement,
      score: 0
    } as BlockReference
  }

  bindEvents (add: boolean): void {
    const method = bindMethod(add)
    this.refs.bottomBarButton?.[method]('click', this.onBottomBarButtonClick)
    this.refs.bottomBarPrev?.[method]('click', this.onBottomBarPrevClick)
    this.refs.bottomBarNext?.[method]('click', this.onBottomBarNextClick)
  }

  onBottomBarPrevClick = (e: Event) => {
    this.goToBlock(-1)
  }

  onBottomBarNextClick = (e: Event) => this.goToBlock(+1)

  goToBlock = (increment: number) => {
    if (!this.current.length) return
    const index = findIndex(this.blocks, block => block === this.current[0])
    const nextIndex = math.clamp(index + increment, 0, this.blocks.length)
    const next = this.blocks[nextIndex]
    if (!next) return
    scroll.scrollTo(next.wrapper?.offsetTop)
  }

  onBottomBarButtonClick = (e: Event) => {
    this.el?.classList.toggle('show-popin')
    const hasClass = this.el.classList.contains('show-popin')
    store.popin.set(hasClass ? 'sticky-bottom-bar' : null)
  }

  flush () {
    super.flush()
    if (this.io) this.io.disconnect()
    if (this.mo) this.mo.disconnect()
  }

  onMutations = (mutations: MutationRecord[]) => {
    this.updateBlocks()
  }

  onIntersectsUpdate = (entries:IntersectionObserverEntry[]) => {
    const d = dimensions()
    Array.from(entries).forEach(entry => {
      const block = find(this.blocks, block => block.wrapper === entry.target)
      if (!block) return
      const iWidth = entry.intersectionRect.width / d.width
      const iHeight = entry.intersectionRect.height / (d.height / 2)
      block.score = iWidth * iHeight
    })

    const firsts = this.blocks
      .slice(0)
      .filter(a => a && a.score > 0.2)
      .filter(a => a.wrapper.style.visibility !== 'hidden')
      .sort((a, b) => b.score - a.score)

    if (firsts.length > 1 && firsts[0].score === firsts[1].score)
      this.updateCurrent(firsts[0], firsts[1])
    else this.updateCurrent(firsts[0])
  }

  updateCurrent = (ref:BlockReference, ref2?:BlockReference) => {
    if (this.current?.includes(ref)) return
    if (ref2 && this.current?.includes(ref2)) return

    this.bindEvents(false)
    if (ref && ref2) {
      this.el.className = ref.bar.className + ' sticky-bottom-bar__diptych'
      const inner = document.createElement('div')
      inner.classList.add('sticky-bottom-bar__inner')
      inner.innerHTML = ref.bar.children[0].innerHTML + ref2.bar.children[0].innerHTML
      this.el.innerHTML = inner.outerHTML
      this.current = [ref, ref2]

      defer(() => {
        this.bindRefs()
        this.bindEvents(true)
        router.updatePageLinks()
      })
    } else if (!ref) {
      // this.el.className = ''
      // this.el.innerHTML = ''
      // this.current = []
      if (this.refs.bottomBarPrev) this.bindEvents(true)
    } else {
      this.el.className = ref.bar.className
      this.el.innerHTML = ref.bar.innerHTML
      this.current = [ref]
      const index = findIndex(this.blocks, block => block === ref)

      this.el[attributeMethod(isFirst(index))]('data-first', '')
      this.el[attributeMethod(isLast(index, 1, this.blocks.length - 1))]('data-last', '')

      defer(() => {
        this.bindRefs()
        this.bindEvents(true)
        router.updatePageLinks()
      })
    }
  }
}

export default StickyBottomBar
