import { IPageClass } from 'navigation/pages/Page'
import Brand from 'pages/brand/Brand'
import Cart from 'pages/cart/Cart'
import Collection from 'pages/collection/Collection'
import Home from 'pages/home/Home'
import Page404 from 'pages/page404/Page404'
import Product from 'pages/product/Product'
import Search from 'pages/search/Search'

const pageMap = {
  collection: Collection,
  product: Product,
  index: Home,
  search: Search,
  cart: Cart,
  'page.brand': Brand,
  page404: Page404 as any
} as Record<string, IPageClass>

export default pageMap
