import { mapValues } from 'lodash-es'
import { TinyEmitter } from 'tiny-emitter'

type Callback<T> = (value: T, previous: T) => any

export class State<T> {
  emitter: TinyEmitter
  value: T
  name?: string

  constructor (value: T, name?: string) {
    this.emitter = new TinyEmitter()
    this.value = value
    this.name = name
  }

  toggleListener (add: boolean, callback: Callback<T>, context?: any) {
    if (add) return this.listen(callback, context)
    else return this.unlisten(callback)
  }

  listenAndStart (callback: Callback<T>, context?: any) {
    this.listen(callback, context)
    callback(this.value, this.value)
  }

  listen (callback: Callback<T>, context?: any) { this.emitter.on('', callback, context) }
  listenOnce (callback: Callback<T>, context?: any) { this.emitter.once('', callback, context) }
  unlisten (callback?: Callback<T>) { this.emitter.off('', callback) }
  destroy () { this.unlisten() }

  set (value: T, force?:boolean) {
    if (!force && this.value === value) return
    const previous = this.value
    this.value = value
    this.emitter.emit('', value, previous)
  }

  get () { return this.value }
}

export type StoreType <T> = {
  [K in keyof T]: State<T[K]>
}

export function createStore<T extends Record<string, any>> (state : T) {
  let emitters = {} as StoreType<T>
  for (const k in state) emitters[k] = new State(state[k], k)
  emitters = Object.freeze(emitters)
  return emitters
}

export function exportStore<T extends Record<string, State<any>>> (store : T) {
  return mapValues(store, (v) => v.get())
}

export default createStore
