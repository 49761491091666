import { ModulesMappping } from 'core/modulesMap'
import './ProductCarousel.scss'
import Component from 'navigation/component/Component'

import ProductCard from '../product-card/ProductCard'

type ProductCarouselType = {
  modules: {}
}

class ProductCarousel extends Component<ProductCarouselType> {
  constructor (el: HTMLElement, options:any) {
    super(el, options)
    this.bindModules()
  }

  getModulesMap (): ModulesMappping {
    return {
      productCard: ['.product-card', ProductCard]
    }
  }
}

export default ProductCarousel
