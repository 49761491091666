import Component from 'navigation/component/Component'
import './HomeEmbed.scss'

class HomeEmbed extends Component {
  constructor (el: HTMLElement) {
    super(el)
    this.bindRefs()
  }

  initialized (): void {
    super.initialized()
  }
}

export default HomeEmbed
