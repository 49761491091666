import Component from 'navigation/component/Component'
import './HomeProductRow.scss'
import { ModulesMappping } from 'core/modulesMap'
import ProductCard from 'components/product/product-card/ProductCard'

class HomeProductRow extends Component {
  constructor (el: HTMLElement, options:any) {
    super(el, options)
    this.bindModules()
  }

  getModulesMap (): ModulesMappping {
    return {
      productCard: ['.product-card', ProductCard]
    }
  }
}

export default HomeProductRow
