import { CollectionStore } from 'stores/collectionStore'

export type CollectionActions = {
}

const createCollectionAction = (CollectionStore: CollectionStore) : CollectionActions => {
  return { }
}

export { createCollectionAction }
