import Component from 'navigation/component/Component'
import './WishlistItem.scss'
import { ModulesMappping } from 'core/modulesMap'
import { ProductSizes } from 'components/product/product-sizes/ProductSizes'
import SelectNative from 'components/select-native/SelectNative'
import persistentStore from 'stores/persistentStore'
import { bindEmitterMethod, bindMethod } from 'helpers/bind'
import { addToWishlist, getItem, removeFromWishlist, replaceInWishlist } from 'actions/wishlistActions'
import cart from 'stores/cart'

type WishlistItemType = {
  refs: {
    sizeSelect: HTMLSelectElement;
    wishlistRemove: HTMLButtonElement;
    wishlistAddToCart: HTMLButtonElement;
  };
  modules: {
    productSizes: ProductSizes;
    productSelect: SelectNative;
  };
};
class WishlistItem extends Component<WishlistItemType> {
  private variantId: string = ''

  getModulesMap (): ModulesMappping {
    return {
      productSizes: ['.product-sizes', ProductSizes],
      productSelect: ['.cart-item__size-select', SelectNative]
    }
  }

  initialized (): void {
    const card = this.el.children[0] as HTMLElement
    this.variantId = card.dataset.variantId || ''
    this.bindRefs()
    this.bindModules()
    super.initialized()
  }

  bindEvents (add = true): void {
    const method = bindMethod(add)
    const emitterMethod = bindEmitterMethod(add)
    if (this.modules.productSizes) this.modules.productSizes[emitterMethod]('change', this.onSizeChange)
    if (this.modules.productSelect) this.modules.productSelect[emitterMethod]('change', this.onSizeChange)
    this.refs.wishlistRemove[method]('click', this.onRemove)
    this.refs.wishlistAddToCart[method]('click', this.onAddToCart)
  }

  onSizeChange = async (variantId: string) => {
    const wishlist = persistentStore.wishlist.get().slice(0)
    const entry = wishlist.find((entry) => entry.includes(this.variantId))
    cart.loading.set(true)

    if (entry) {
      this.bindEvents(false)
      const newEntry = entry?.replace(this.variantId, variantId)

      const index = replaceInWishlist(entry, newEntry)
      const content = await getItem(persistentStore.wishlist.get()[index])
      const html = new DOMParser().parseFromString(content, 'text/html')

      this.el!.innerHTML = html.querySelector('.wishlist-item')!.innerHTML
      this.initialized()
    }
    setTimeout(() => cart.loading.set(false), 200)
  }

  onRemove = () => {
    const wishlist = persistentStore.wishlist.get().slice(0)
    const entry = wishlist.find((entry) => entry.includes(this.variantId))
    if (entry) {
      removeFromWishlist(entry)
      this.el.remove()
      this.emit('remove')
    }
  }

  onAddToCart = async () => {
    await cart.addToCart({
      id: this.variantId,
      ghostSrc: (this.el.children[0] as HTMLElement).dataset.featured,
      sections: ['main-cart-header', 'main-cart-items', 'main-cart-footer', 'cart-sticky-bar']

    })
    this.onRemove()
    cart.view.set('cart')
  }
}

export default WishlistItem
