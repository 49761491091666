import anime, { AnimeInstance } from 'animejs'
import { bindMethod } from 'helpers/bind'
import Component from 'navigation/component/Component'

type SnapCarouselType = {
  refs:{
    tracks: HTMLButtonElement[]
    slider: HTMLElement
  }
}

export default class SnapCarousel extends Component<SnapCarouselType> {
  private animation:AnimeInstance | null = null
  private switching:boolean = false // when navigating slider via tracks, to remove scroll-snap
  private count:number = 0 // number of images
  public fraction:number = 0 // fraction of slider to get image width/scroll offset
  constructor (el:HTMLElement, options:any) {
    super(el, options)
    this.bindRefs()
  }

  initialized (): void {
    super.initialized()
    this.count = this.refs.tracks.length
    this.fraction = this.refs.slider.scrollWidth / this.count
    this.bindEvents()
  }

  bindEvents (add = true): void {
    const method = bindMethod(add)
    if (this.refs.slider) {
      this.refs.slider[method]('scroll', this.onScroll)
      if (this.refs.tracks && this.refs.tracks.length) {
        this.refs.tracks.forEach((track: HTMLElement) => {
          track[method]('click', this.onTrackClick)
        })
      }
    }
  }

  onTrackClick = async (event:Event) => {
    event.preventDefault()
    event.stopPropagation()

    const index = this.refs.tracks.indexOf(event.currentTarget as HTMLButtonElement)
    this.refs.slider.classList.add('switching')

    const options = {
      scrollLeft: this.refs.slider.scrollLeft
    }
    this.switching = true
    if (this.animation) this.animation.pause()
    this.setActiveTrack(index)
    this.animation = anime({
      targets: options,
      scrollLeft: index * this.fraction,
      easing: 'easeOutExpo',
      update: () => {
        this.refs.slider.scrollLeft = options.scrollLeft
      },

      duration: 1000
    })
    await this.animation.finished
    this.refs.slider.classList.remove('switching')
    this.switching = false
  }

  onScroll = (e:Event) => {
    if (this.switching) return

    const active = Math.round(this.refs.slider.scrollLeft / this.fraction)
    this.setActiveTrack(active)
  }

  setActiveTrack = (active: number) => {
    this.refs.tracks.forEach((track: HTMLElement, index: number) => {
      if (index === active)
        track.classList.add('active')
      else
        track.classList.remove('active')
    })
  }

  resize (): void {
    super.resize()
    this.fraction = this.refs.slider.scrollWidth / this.count
  }
}
