import StickyBottomBar from 'components/sticky-bottom-bar/StickyBottomBar'
import HomeCarousel from 'components/home-carousel/HomeCarousel'
import HomeDiptych from 'components/home-diptych/HomeDiptych'
import HomeOneLinkDiptych from 'components/home-one-link-diptych/HomeOneLinkDiptych'
import HomeProductRow from 'components/home-product-row/HomeProductRow'
import HomeProductsDiptych from 'components/home-products-diptych/HomeProductsDiptych'
import HomeSingle from 'components/home-single/HomeSingle'
import ProductCard from 'components/product/product-card/ProductCard'
import { ModulesMappping } from 'core/modulesMap'
import InternalRouter from 'navigation/pages/InternalRouter'
import MainPage from 'navigation/pages/MainPage'
import HomeEmbed from 'components/home-embed/HomeEmbed'

type HomeType = {
  modules : {
    homeBottomMainBar : StickyBottomBar,
  },
}

class Home extends MainPage<HomeType> {
  getModulesMap (): ModulesMappping {
    return {
      ...super.getModulesMap(),
      homeCarousel: ['.home-carousel', HomeCarousel],
      homeSingle: ['.home-single', HomeSingle],
      homeDiptych: ['.home-diptych', HomeDiptych],
      HomeEmbed: ['.home-embed', HomeEmbed],

      homeOneLinkDiptych: ['.home-one-link-diptych', HomeOneLinkDiptych],
      homeProductsDiptych: ['.home-products-diptych', HomeProductsDiptych],
      homeProductRow: ['.home-product-row', HomeProductRow]
    }
  }

  constructor (...args: ConstructorParameters<typeof InternalRouter>) {
    super(...args)
    this.bindRefs()
  }
}

Home.pageName = 'Home'

export default Home
