import Component from 'navigation/component/Component'
import './CartTabs.scss'
import { bindMethod } from 'helpers/bind'
import cart from 'stores/cart'
import persistentStore from 'stores/persistentStore'

export type CartTabsType = {
refs:{
  wishlistToggle:HTMLButtonElement
  cartToggle:HTMLButtonElement
  cartCount:HTMLSpanElement
  wishlistCount:HTMLSpanElement
}
}
export default class CartTabs extends Component<CartTabsType> {
  constructor (el:HTMLElement, options:any) {
    super(el, options)
    this.bindRefs()
  }

  initialized (): void {
    super.initialized()
    const view = cart.view.get()
    this.updateView(view)
    this.refs.wishlistCount.innerHTML = persistentStore.wishlist.get().length + ''
  }

  bindEvents (add = true): void {
    const method = bindMethod(add)
    cart.count.toggleListener(add, this.onCartUpdate)
    cart.view.toggleListener(add, this.updateView)
    persistentStore.wishlist.toggleListener(add, this.onWishlistUpdate)
    this.refs.wishlistToggle[method]('click', () => cart.view.set('wishlist'))
    this.refs.cartToggle[method]('click', () => cart.view.set('cart'))
  }

  onWishlistUpdate = (items:any[]) => {
    this.refs.wishlistCount.innerText = `${items && items.length}`
  }

  updateView = (view:'wishlist'|'cart') => {
    if (view === 'cart') {
      this.refs.cartToggle.classList.add('active')
      this.refs.wishlistToggle.classList.remove('active')
      window.location.hash = ''
      // window.history.pushState({}, '', window.location.pathname)
    }
    if (view === 'wishlist') {
      this.refs.cartToggle.classList.remove('active')
      this.refs.wishlistToggle.classList.add('active')
      window.location.hash = 'wishlist'

      // if(!window.location.hash.includes('wishlist')) {
      // window.history.pushState({}, '', '?view=wishlist')
      // }
    }
    this.emit('toggle', view)
  }

  onCartUpdate = (count:number) => {
    this.refs.cartCount.innerText = `${count}`
  }
}
