import Component from 'navigation/component/Component'
import './HomeSingle.scss'
import homepageStore from 'stores/homepageStore'

class HomeSingle extends Component {
  constructor (el: HTMLElement) {
    super(el)
    this.bindRefs()
  }

  initialized (): void {
    super.initialized()
    homepageStore.blocksEl.set([...homepageStore.blocksEl.get(), this.el])
  }
}

export default HomeSingle
