import { CartActions, createCartAction } from 'actions/cartActions'
import { createStore, StoreType } from 'helpers/state'

export type CartSection = 'main-cart-header' | 'main-cart-items' | 'main-cart-footer' | 'cart-sticky-bar'

export type CartContent = {
  [K in CartSection]: string
}

type CartState = {
  content: CartContent | null
  count: number
  loading:boolean
  view:'cart'|'wishlist'
 }

export type cart = StoreType<CartState>
export type CartContext = cart & CartActions

const createCartContext = () : CartContext => {
  const store = createStore<CartState>({
    content: null,
    count: 0,
    loading: false,
    view: 'cart'
  })
  const actions = createCartAction(store)
  return { ...store, ...actions }
}

export default createCartContext()
