import { ModulesMappping } from 'core/modulesMap'
import CartTabs from 'components/cart/cart-tabs/CartTabs'
import CartItem from 'components/cart/cart-item/CartItem'
import MainPage from 'navigation/pages/MainPage'
import CartFooter from 'components/cart/cart-footer/CartFooter'
import { bindEmitterMethod } from 'helpers/bind'
import ProductsRelated from 'components/product/products-related/ProductsRelated'
import CartSuggestions from 'components/cart/cart-suggestions/CartSuggestions'
import CartStickyBar from 'components/cart/cart-sticky-bar/CartStickyBar'
import cart, { CartContent } from 'stores/cart'
import CartItems from 'components/cart/cart-items/CartItems'
import ReinsuranceTabs from 'components/reinsurance-tabs/ReinsuranceTabs'
import Wishlist from 'components/wishlist/Wishlist'
import router from 'core/router'
import './Cart.scss'

type CartType = {
  modules: {
    tabs:CartTabs
    items:CartItems
    footer:CartFooter
    cartStickyBar:CartStickyBar
    cartSuggestions:CartSuggestions
    wishlist:Wishlist
  }
}

class Cart extends MainPage<CartType> {
  getModulesMap (): ModulesMappping {
    return {
      ...super.getModulesMap(),
      tabs: ['.cart-tabs', CartTabs],
      item: ['.cart-item', CartItem],
      items: ['.cart-items', CartItems],
      wishlist: ['.cart-wishlist', Wishlist],
      footer: ['.cart-footer', CartFooter],
      cartSuggestions: ['.cart-suggestions', CartSuggestions],
      productsRelated: ['.products-related', ProductsRelated],
      reinsuranceTabs: ['.reinsurance-tabs', ReinsuranceTabs],
      cartStickyBar: ['.cart-sticky-bar', CartStickyBar]
    }
  }

  initialized (): void {
    if (window.location.hash.includes('wishlist'))
      cart.view.set('wishlist')

    super.initialized()
  }

  bindEvents (add = true): void {
    const emitterMethod = bindEmitterMethod(add)
    cart.content.toggleListener(add, this.onCartUpdate)
    this.modules.tabs[emitterMethod]('toggle', this.onToggle)
    cart.view.toggleListener(add, this.onToggle)
  }

  onCartUpdate = (content: CartContent | null) => {
    if (!content) return
    this.modules.items.flush()
    this.modules.footer.flush()
    this.modules.cartStickyBar.flush()

    // this.modules.tabs.el.outerHTML = content.
    this.modules.items.el.outerHTML = content['main-cart-items'] || ''
    this.modules.cartStickyBar.el.outerHTML = content['cart-sticky-bar'] || ''
    this.modules.footer.el.outerHTML = content['main-cart-footer'] || ''

    cart.content.toggleListener(false, this.onCartUpdate)

    this.bindModules()
    router.updatePageLinks()

    this.modules.items.reveal()
    this.modules.cartStickyBar.reveal()
    this.modules.footer.reveal()
    this.modules.cartSuggestions.reveal()
  }

  onToggle = (item: 'wishlist' | 'cart') => {
    if (item === 'cart') {
      this.modules.wishlist.hide()
      this.modules.items.reveal()
      this.modules.cartStickyBar.reveal()
      this.modules.footer.reveal()
      this.modules.cartSuggestions.reveal()
    } else {
      this.modules.wishlist.reveal()
      this.modules.items.hide()
      this.modules.cartStickyBar.hide()
      this.modules.footer.hide()
      this.modules.cartSuggestions.hide()
    }
  }

  async askHide (nextPage?: string) {
    await this.hide(nextPage)
    this.state.hidden = true
  }

  flush (): void {
    cart.view.set('cart')
    super.flush()
  }
}

Cart.pageName = 'cart'

export default Cart
