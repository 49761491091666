import Component from 'navigation/component/Component'
import './Wishlist.scss'
import { ModulesMappping } from 'core/modulesMap'
import WishlistItem from 'components/wishlist-item/WishlistItem'
import { getItems } from 'actions/wishlistActions'
import persistentStore from 'stores/persistentStore'
import promise from 'helpers/promise'
import cart from 'stores/cart'

type WishlistType = {
  modules:{
    items: WishlistItem[]
  }
}
class Wishlist extends Component<WishlistType> {
  constructor (el: HTMLElement) {
    super(el)
    this.bindRefs()
  }

  getModulesMap (): ModulesMappping {
    return {
      items: ['.wishlist-item', WishlistItem]
    }
  }

  async initialized (): Promise<void> {
    this.el.classList.add('loading')
    const itemsExist = persistentStore.wishlist.get().length
    if (itemsExist) {
      this.el.innerHTML += await getItems()
      await promise.wait(500)
      this.el.classList.remove('loading')
    } else { this.el.classList.remove('loading') }

    this.bindModules()
    this.bindEvents()
  }

  async bindEvents (add = true): Promise<void> {
    cart.loading.toggleListener(add, this.toggleLoading)
  }

  toggleLoading = (loading: boolean) => {
    this.el.classList.toggle('loading', loading)
  }

  hide () {
    this.el.classList.remove('cart-wishlist__active')
  }

  reveal () {
    this.el.classList.add('cart-wishlist__active')
  }
}

export default Wishlist
