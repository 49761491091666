import { defer } from 'lodash-es'
import store from 'stores'
import Component from 'navigation/component/Component'
import router from 'core/router'
import resize, { dimensions } from 'helpers/resize'
import PageManager from 'navigation/page-manager/MainPageManager'
import MainPage from 'navigation/pages/MainPage'
import { ModulesMappping } from 'core/modulesMap'
import scroll from 'core/scroll'
import Header from 'components/header/Header'
import Overlay from 'components/overlay/Overlay'
import { bindMethod } from 'helpers/bind'
import Toaster from 'components/toaster/Toaster'
import { MarketSelector } from 'components/market-selector/MarketSelector'
import './App.scss'
import Favicon from 'components/favicon/Favicon'
import PopinNewsletter from 'components/popin-newsletter/PopinNewsletter'

type AppType = {
  refs: {
    main: HTMLElement
    gridHelper: HTMLElement
  }
  modules: {
    header: Header
    pageManager: PageManager
  }
}

class App extends Component<AppType> {
  pageManager?: PageManager

  getModulesMap = () => ({
    favicon: ['link.favicon', Favicon],
    header: ['.header', Header],
    overlay: ['.overlay', Overlay],
    toaster: ['.toaster', Toaster],
    marketSelector: ['.market-selector', MarketSelector],
    PopinNewsletter: ['.popin-newsletter', PopinNewsletter]
  }) as ModulesMappping

  constructor (el: HTMLElement) {
    super(el)
    scroll.init()

    this.bindRefs()
    this.bindModules()

    resize.setRoot(this.refs.main)
    resize.add(this)

    router.resolve()
    if (process.env.NODE_ENV === 'development')
      this.debug()

    // setTimeout(() => {
    //   resize.clear()
    //   resize.resize()
    // }, 100)
  }

  bindModules (): void {
    this.pageManager = new PageManager(this.refs.main, '.page', MainPage)
    this.pageManager.once('show', this.show)

    super.bindModules()

    store.routers.get().main = this.pageManager
    this.modules.pageManager = this.pageManager
  }

  show = () => {
    this.invoke('show')
  }

  bindEvents () {
    resize.add(this)

    scroll.on(this.scroll)
    store.loading.listenAndStart(this.onLoading)

    this.resize()
    this.scroll()
  }

  onLoading = (loading: boolean) => {
    document.documentElement.classList.toggle('loading', loading)
  }

  resize = () => {
    defer(() => {
      super.resize()
    })
    this.updateCSSVars()
  }

  updateCSSVars = () => {
    document.documentElement.style.setProperty('--vh', `${dimensions().height}px`)
    document.documentElement.style.setProperty('--ivh', `${dimensions().innerHeight}px`)
    document.documentElement.style.setProperty('--vw', `${dimensions().width}px`)
  }

  scroll = () => {
  }

  debug = () => {
    const method = bindMethod(true)
    window[method]('keypress', (e: Event) => {
      if ((e as KeyboardEvent).code === 'KeyG')
        this.refs.gridHelper.classList.toggle('visible')
    })
  }
}

export default App
