import Component from 'navigation/component/Component'
import './SelectButton.scss'
import { bindMethod } from 'helpers/bind'
import store from 'stores'

type SelectButtonType = {
  refs: {
    selectButtonOption: HTMLElement[]
    selectButton: HTMLElement,
    selectButtonOverlay: HTMLElement
  }
}

class SelectButton extends Component<SelectButtonType> {
  constructor (el: HTMLElement) {
    super(el)
    this.bindRefs()
  }

  bindEvents (add = true) {
    const method = bindMethod(add)

    if (!this.refs.selectButtonOption?.length) return
    this.refs.selectButton[method]('click', this.toggle)
    this.refs.selectButtonOverlay[method]('click', this.toggle)
    this.refs.selectButtonOption?.forEach((option) => {
      option[method]('click', this.handleOptionClick)
    })
    window[method]('click', this.close)
  }

  toggle = () => {
    this.el.classList.toggle('opened')
    const hasClass = this.el.classList.contains('opened')
    if (hasClass) this.emit('opened')
    else this.emit('closed')

    store.popin.set(hasClass ? 'selector' : null)
  }

  close = (e:Event) => {
    if (!this.el.contains(e.target as Node)) {
      this.el.classList.remove('opened')
      this.emit('closed')
    }
  }

  handleOptionClick = (e: Event) => {
    const target = e.target as HTMLElement
    if (!this.refs.selectButton) return
    this.el.classList.toggle('opened')
    this.emit('closed')
    store.popin.set(null)

    // this.refs.selectButton.innerHTML = target.innerHTML
  }
}

export default SelectButton
