import Component from "navigation/component/Component";
import './ProductSizes.scss'
import { defer } from "lodash-es";
import { bindMethod } from "helpers/bind";

type ProductSizesType = {
  refs: {
    switches: HTMLButtonElement[]

  }
}
export class ProductSizes extends Component<ProductSizesType> {
  constructor(el:HTMLElement, options:any) {
    super(el, options)
    this.bindRefs()
  }
  initialized(): void {
    super.initialized()
  }
  bindEvents(add=true): void {
    const method = bindMethod(add)
    this.refs.switches.forEach(size => {
      size.addEventListener('click', this.onClick)
    })
  }
  onClick = (e:Event) => {
    const target = e.target as HTMLButtonElement
    this.emit('change',target.dataset.variant || '')
  }
  flush(): void {
    super.flush()
  }
}
