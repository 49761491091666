import './ProductListStickyBar.scss'
import Component from 'navigation/component/Component'
import { bindMethod } from 'helpers/bind'
import store from 'stores'
import { CollectionGridTypes } from 'stores/collectionStore'
import Filters from 'components/filters/Filters'

type ProductListStickyBarType = {
  refs: {
    collectionDropdown: HTMLElement
    collectionToggle: HTMLElement
    collectionLinks: HTMLElement[]
    filtersDropdown: HTMLElement
    filtersToggle: HTMLElement
    displaySwitches: HTMLElement[]
    displayToggle: HTMLElement
    displayDropdown: HTMLElement,
    filtersCount: HTMLElement
    filtersLabel: HTMLElement
  }
}

const activePopins = ['collection', 'display']

class ProductListStickyBar extends Component<ProductListStickyBarType> {
  private _view: 'row' | 'grid' | 'story' = 'grid'
  private _panel: 'filters' | 'collection' | 'display' | null = null

  get view () {
    return this._view
  }

  set view (view: CollectionGridTypes) {
    this._view = view
    this.switchActiveButton(view)
    if (view !== 'grid')
      window.location.hash = view
    else
      window.location.hash = ''
    this.emit('switchView', view)
  }

  get panel () {
    return this._panel
  }

  set panel (panel: 'filters' | 'collection' | 'display' | null) {
    if (panel === this._panel) this._panel = null
    else this._panel = panel
    if (this._panel === 'filters') { store.filtersOpened.set(true) } else {
      store.filtersOpened.set(false)
      store.popin.set(null)
    }

    this.el.dataset.panel = this._panel || ''
  }

  constructor (el: HTMLElement) {
    super(el)
    this.view = store.view.get()
    this.bindRefs()
  }

  initialized (): void {
    // const grid = document.querySelector('.product-grid') as HTMLElement

    // if (window.location.hash) {
    //   this.view = window.location.hash.slice(1) as CollectionGridTypes
    // } else {
    //   if (grid)
    //     this.view = grid.dataset.defaultView as CollectionGridTypes
    // }
    super.initialized()
    this.view = store.view.get()
  }

  bindEvents (add = true) {
    const method = bindMethod(add)
    if (this.refs.filtersToggle) this.refs.filtersToggle[method]('click', () => this.togglePanel('filters'))
    if (this.refs.collectionToggle) this.refs.collectionToggle[method]('click', () => this.togglePanel('collection'))
    if (this.refs.displayToggle) this.refs.displayToggle[method]('click', () => this.togglePanel('display'))
    if (this.refs.displaySwitches) this.refs.displaySwitches.forEach(switchEl => switchEl[method]('click', this.onSwitch))
    if (this.refs.collectionLinks) this.refs.collectionLinks.forEach(link => link[method]('mouseover', this.onLinkOver))
    if (this.refs.collectionDropdown) this.refs.collectionDropdown[method]('mouseleave', this.onDropdownLeave)
    store.filtersOpened.toggleListener(add, (value:boolean) => { if (!value) this.panel = null })
    window.addEventListener('click', this.onClickOutside)
    store.popin.toggleListener(add, this.toggleActive)
    store.view.toggleListener(add, () => {
      this.view = store.view.get()
    })
    // Hide filters on click outside
  }

  onLinkOver = (e:Event) => {
    this.refs.collectionLinks.forEach(link => {
      link.classList.remove('active')
      link.classList.add('inactive')
    })
    const link = e.target as HTMLElement
    link.classList.add('active')
  }

  onDropdownLeave = () => {
    this.refs.collectionLinks.forEach(link => {
      if (link.dataset.active === undefined) {
        link.classList.remove('active')
        link.classList.add('inactive')
      } else {
        link.classList.remove('inactive')
        link.classList.add('active')
      }
    }
    )
  }

  toggleActive = () => {
    this.el.classList.toggle('active', activePopins.includes(store.popin.get()!))
  }

  togglePanel = (panel: 'filters' | 'collection' | 'display' | null) => {
    if (this.panel === panel) panel = null
    this.panel = panel
    store.popin.set(panel)
  }

  switchActiveButton = (view: CollectionGridTypes) => {
    if (this.refs.displaySwitches) {
      this.refs.displaySwitches.forEach(switchEl => {
        switchEl.classList.remove('active')
        if (switchEl.dataset.mode === view) switchEl.classList.add('active')
      })
    }
    if (this.refs.displayToggle) this.refs.displayToggle.innerHTML = view
  }

  onClickOutside = (e: MouseEvent) => {
    const filters: Filters = this.parent!.modules!.filters
    if (!this.el.contains(e.target as HTMLElement) && this.panel && !filters.el.contains(e.target as HTMLElement)) this.panel = null
  }

  onSwitch = (e:Event) => {
    const view = (e.target as HTMLElement).dataset.mode as CollectionGridTypes
    store.view.set(view)
    this.togglePanel(null)
  }

  updateFilters = (value:number) => {
    if (!this.refs.filtersCount) return
    const w = window as any
    if (value > 0) {
      this.refs.filtersCount.classList.add('visible')
      this.refs.filtersCount.innerHTML = value.toString()
      if (value > 1)
        this.refs.filtersLabel.innerHTML = w.variantStrings.filters.many

      else
        this.refs.filtersLabel.innerHTML = w.variantStrings.filters.one
    } else {
      this.refs.filtersCount.classList.remove('visible')
      this.refs.filtersLabel.innerHTML = w.variantStrings.filters.one
    }
  }
}

export default ProductListStickyBar
