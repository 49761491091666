import { bindEmitterMethod, bindMethod } from 'helpers/bind'
import Component from 'navigation/component/Component'
import { ModulesMappping } from 'core/modulesMap'
import SelectButton from 'components/select-button/SelectButton'
import { ProductContext } from 'stores/productStore'
import persistentStore from 'stores/persistentStore'
import cart from 'stores/cart'
import { addToWishlist, removeFromWishlist } from 'actions/wishlistActions'
import store from 'stores'

import ProductPopupBar from '../product-popup-bar/ProductPopupBar'
import './ProductStickyBar.scss'
import ProductThumbnails from '../product-thumbnails/ProductThumbnails'

type ProductStickyBarType = {
  modules: {
    colorSelector: SelectButton,
    variantSelector: SelectButton,
    popupBar: ProductPopupBar
  }
  refs: {
    productPopupButton: HTMLElement,
    productActions : HTMLElement,
    addToCart: HTMLButtonElement,
    wishlistButton: HTMLElement,
  }
  context: ProductContext
}

const activePopins = ['product-pop-up-bar', 'selector']

class ProductStickyBar extends Component<ProductStickyBarType> {
  getModulesMap (): ModulesMappping {
    return {
      thumbnails: ['.product-sticky-bar__thumbnails', ProductThumbnails],
      popupBar: ['.product-popup-bar', ProductPopupBar],
      colorSelector: ['.product-sticky-bar__color', SelectButton],
      variantSelector: ['.product-sticky-bar__variant', SelectButton]
    }
  }

  initialized (): void {
    this.bindRefs()
    this.bindModules()
    super.initialized()
    this.detectWishtlist()
  }

  bindEvents (add = true) {
    const method = bindMethod(add)
    if (!this.refs.addToCart || !this.context) return

    this.refs.addToCart?.[method]('click', this.onAddToCart)
    this.refs.wishlistButton?.[method]('click', this.toggleWishlist)
    this.refs.productPopupButton?.[method]('click', this.modules.popupBar.toggle)

    const emitterMethod = bindEmitterMethod(add)
    this.modules.colorSelector?.[emitterMethod]('opened', this.onDropDownOpened)
    this.modules.variantSelector?.[emitterMethod]('opened', this.onDropDownOpened)

    persistentStore.wishlist.toggleListener(add, this.detectWishtlist)
    if (add) this.detectWishtlist()
    store.popin.toggleListener(add, this.toggleActive)
    // Hide filters on click outside
  }

  toggleActive = () => {
    this.el.classList.toggle('active', activePopins.includes(store.popin.get()!))
  }

  onDropDownOpened = () => {
    this.modules.popupBar.close()
  }

  onAddToCart = async () => {
    this.refs.addToCart.classList.add('loading')
    await cart.addToCart({
      id: this.context.variantId.get()!,
      ghostSrc: (this.el.children[0] as HTMLElement).dataset.featured
    })
    this.refs.addToCart.classList.remove('loading')
  }

  getVariantUrl = () => this.refs.wishlistButton.getAttribute('data-wishlist')

  toggleWishlist = () => {
    const url = this.getVariantUrl()
    if (url) {
      const current = persistentStore.wishlist.get()
      const included = current.includes(url)
      const firstChild = this.el.firstElementChild as HTMLElement
      if (included) removeFromWishlist(url)
      else addToWishlist(url, firstChild.dataset.name || '', firstChild.dataset.featured || '')
    }
  }

  detectWishtlist = () => {
    const url = this.getVariantUrl()
    const current = persistentStore.wishlist.get()
    if (url)
      this.refs.wishlistButton.classList.toggle('toggle', current.includes(url))
  }

  refresh (el: HTMLElement) {
    const clone = new Component<ProductStickyBarType>(el)
    clone.bindRefs()

    this.bindEvents(false)

    this.refs.productActions.innerHTML = clone.refs.productActions.innerHTML
    this.refs = {} as any

    this.initialized()
  }

  onVariantUpdate = (variant: any) => {
  }
}

export default ProductStickyBar
