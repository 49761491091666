import Component from 'navigation/component/Component'
import './BrandItem.scss'
import { bindEmitterMethod, bindMethod } from 'helpers/bind'
import math from 'helpers/math'
import InfiniteLine from 'components/infinite-line/InfiniteLine'
import { ModulesMappping } from 'core/modulesMap'
import mqStore from 'stores/mqStore'
import VerticalInfiniteLine from 'components/infinite-line/VerticalInfiniteLine'

type BrandItemType = {
  refs:{
    columns: HTMLElement[]
    images: HTMLElement[]
  }
  modules:{
    infiniteLine: InfiniteLine
    verticalInfiniteLine: VerticalInfiniteLine[]
  }
}
type MouseInfo = {
  x: number
  y: number
  time: number
  startX: number
  startY: number
} | null

const decay = .2
export default class BrandItem extends Component<BrandItemType> {
  static minColumns = 3
  private targetX = 0
  private x = 0
  private y = 0
  private targetY = 0
  private raf = 0
  private mouse: MouseInfo = null
  public active = false

  initialized (): void {
    this.bindRefs()
    this.bindModules()
    this.setWidths()
    super.initialized()
  }

  setWidths = () => {
    for (let i = 0; i < this.refs.columns.length; i++) {
      const images = Array.from(this.refs.columns[i].children)
      const widths :number[] = images.map((el) => el.getBoundingClientRect().width)
      const maxWidth = Math.max(...widths)
      this.refs.columns[i].style.width = `${maxWidth}px`
    }
  }

  getModulesMap (): ModulesMappping {
    return {
      infiniteLine: ['.brand-item__columns', InfiniteLine],
      verticalInfiniteLine: ['.brand-item__column', VerticalInfiniteLine]
    }
  }

  bindEvents (add = true): void {
    const method = bindMethod(add)
    const emitterMethod = bindEmitterMethod(add)
    this.el[method]('wheel', this.onWheel)

    if (add) this.onUpdate()
    else cancelAnimationFrame(this.raf)

    this.el?.parentElement?.[method]('mousedown', this.onMouseDown)
    window[method]('mousemove', this.onMouseMove)
    window[method]('mouseup', this.onMouseUp)
    this.el.parentElement?.[method]('touchstart', this.onTouchStart)
    this.el.parentElement?.[method]('touchmove', this.onTouchMove)
    this.el.parentElement?.[method]('touchend', this.onTouchEnd)

    this.modules.infiniteLine[emitterMethod]('restructure', this.onRestructure)
  }

  onRestructure = (): void => {
    this.modules.infiniteLine.reset()
    this.bindModules()
    this.setWidths()
  }

  onWheel = (e: WheelEvent): void => {
    e.preventDefault()
    if (Math.abs(e.deltaX) > Math.abs(e.deltaY))
      this.targetX -= e.deltaX

    else
      this.targetY -= e.deltaY
  }

  onTouchStart = (e: TouchEvent): void => {
    e.preventDefault()
    if (e.touches.length < 1) return
    this.mouse = {
      x: (e as TouchEvent).touches[0].clientX,
      y: (e as TouchEvent).touches[0].clientY,
      time: Date.now(),
      startX: this.targetX,
      startY: this.targetY
    }
  }

  onTouchMove = (e: TouchEvent): void => {
    e.preventDefault()
    if (!this.mouse) return
    const deltaX = (e as TouchEvent).touches[0].clientX - this.mouse.x
    const deltaY = (e as TouchEvent).touches[0].clientY - this.mouse.y

    if (Math.abs(deltaX) > Math.abs(deltaY))
      this.targetX = this.mouse.startX + deltaX

    else
      this.targetY = this.mouse.startY + deltaY
  }

  onTouchEnd = (e: TouchEvent): void => {
    e.preventDefault()
    // this.onTouchMove(e)

    // if (this.mouse) {
    //   const distance = Math.abs(this.mouse?.x - (e as TouchEvent).touches[0].clientX)
    //   const duration = Date.now() - this.mouse?.time

    //   if (distance < 50 && duration < 300) {

    //     const position = this.modules.infiniteLine.items.indexOf(e.target as HTMLElement) % this.modules.infiniteLine.originalItems.length
    //     this.emit('click', position)
    //   }
    // }

    this.mouse = null
  }

  onMouseDown = (e: Event): void => {
    e.preventDefault()
    this.mouse = {
      x: (e as MouseEvent).clientX,
      y: (e as MouseEvent).clientY,
      time: Date.now(),
      startX: this.targetX,
      startY: this.targetY
    }
    this.el.classList.add('is-dragging')
  }

  onMouseMove = (e: Event): void => {
    e.preventDefault()
    if (!this.mouse) return
    const deltaX = (e as MouseEvent).clientX - this.mouse.x
    const deltaY = (e as MouseEvent).clientY - this.mouse.y
    if (Math.abs(deltaX) > Math.abs(deltaY))
      this.targetX = this.mouse.startX + deltaX

    else
      this.targetY = this.mouse.startY + deltaY
  }

  onMouseUp = (e: Event): void => {
    e.preventDefault()
    this.onMouseMove(e)

    if (this.mouse) {
      const distance = Math.abs(this.mouse?.x - (e as MouseEvent).clientX)
      const duration = Date.now() - this.mouse?.time

      if (distance < 50 && duration < 300) {
        const position = this.modules.infiniteLine.items.indexOf(e.target as HTMLElement) % this.modules.infiniteLine.originalItems.length
        this.emit('click', position)
      }
    }

    this.mouse = null
    this.el.classList.remove('is-dragging')
  }

  onUpdate = (): void => {
    this.raf = requestAnimationFrame(this.onUpdate)
    if (this.active) {
      this.targetX -= 1
      // this.targetY -= .3
      this.x += (this.targetX - this.x) * decay
      this.y += (this.targetY - this.y) * decay
      this.modules.infiniteLine.offset = this.x

      this.modules.verticalInfiniteLine.forEach((line, i) => {
        if (i % 2 === 0)
          line.offset = this.y

        else
          line.offset = -this.y
      })
    }
  }

  resize (): void {
    if (this.refs.columns && this.refs.columns.length) this.setWidths()
    super.resize()
    // this.columnWidth = this.refs.columns[0].offsetWidth
  }
}
