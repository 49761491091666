import Component from 'navigation/component/Component'
import { ModulesMappping } from 'core/modulesMap'
import './ProductGallery.scss'
import InfiniteCarousel from 'components/infinite-carousel/InfiniteCarousel'
import { bindEmitterMethod } from 'helpers/bind'
import Product from 'pages/product/Product'
import mqStore from 'stores/mqStore'
import { ProductContext } from 'stores/productStore'

type ProductGalleryType = {
  refs: {}
  modules: {
    carousel: InfiniteCarousel
  }
  options: {
    parent: Product
  },
  context: ProductContext
}

class ProductGallery extends Component<ProductGalleryType> {
  getModulesMap (): ModulesMappping {
    return {
      carousel: ['.product-gallery__inner', InfiniteCarousel]
    }
  }

  constructor (el: HTMLElement, options: any) {
    super(el, options)
    this.bindModules()
  }

  initialized (): void {
    super.initialized()
  }

  bindEvents (add: boolean): void {
    const method = bindEmitterMethod(add)
    this.modules.carousel[method]('click', this.onCarouselClick)
    this.modules.carousel[method]('update', this.onCarouselUpdate)
  }

  onCarouselClick = (position: number): void => {
    this.emit('click', position)
  }

  onCarouselUpdate = (position: number): void => {
    this.context.scrollGalleryPosition.set(position)
  }

  resize (): void {
    if (this.modules.carousel)
      this.modules.carousel.enabled = !mqStore.tabletPortrait.get()
    super.resize()
    this.context.scrollGallerySize.set(this.modules.carousel?.originalSize)
  }
}

export default ProductGallery
