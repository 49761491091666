import Component from 'navigation/component/Component'
import './CartItems.scss'
import cart from 'stores/cart'
export default class CartItems extends Component {
  bindEvents (add = true): void {
    cart.loading.toggleListener(add, () => this.el.classList.toggle('cart-items__loading'))
  }

  hide () {
    this.el.classList.remove('cart-items__active')
  }

  reveal () {
    this.el.classList.add('cart-items__active')
  }

  show () {
    if (!window.location.hash.includes('wishlist'))
      this.reveal()
  }
}
