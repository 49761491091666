import Component from 'navigation/component/Component'
import config from 'core/config'
import detect from 'helpers/detect'

type FaviconType = {}

const size = 32

class Favicon extends Component<FaviconType> {
  private interval: number = 0
  private step = 0
  private img: HTMLImageElement = null!
  private canvas: HTMLCanvasElement = null!
  private canvasContext: CanvasRenderingContext2D = null!

  initialized (): void {
    this.img = new Image()
    this.img.crossOrigin = 'anonymous'
    this.img.src = config.assets.favicon

    this.canvas = document.createElement('canvas')
    this.canvas.width = size
    this.canvas.height = size
    this.canvasContext = this.canvas.getContext('2d') as CanvasRenderingContext2D

    super.initialized()
  }

  bindEvents (add: boolean): void {
    if (!detect.desktop) return
    if (add) this.interval = window.setInterval(this.render, 1000 / 5)
    else window.clearInterval(this.interval)
  }

  render = () => {
    if (!this.img.complete) return
    this.canvasContext.clearRect(0, 0, size, size)
    this.canvasContext.drawImage(this.img, this.step * size, 0, size, size, 0, 0, size, size)
    const blob = this.canvas.toDataURL('image/png')
    this.el.setAttribute('href', blob)
    this.step = (this.step + 1) % 30
  }
}

export default Favicon
