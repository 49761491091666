import Component from 'navigation/component/Component'
import './Show.scss'
import { bindMethod } from 'helpers/bind'
import { ModulesMappping } from 'core/modulesMap'
import InfiniteCarousel from 'components/infinite-carousel/InfiniteCarousel'
import { defer } from 'lodash-es'
import scroll from 'core/scroll'
import mqStore from 'stores/mqStore'
import SnapCarousel from 'components/snap-carousel/SnapCarousel'

type ShowType = {
  refs: {
    closePopin: HTMLButtonElement
    carousel: HTMLElement
    lookItems: HTMLElement[]
    popinLooks: HTMLElement[]
    tracks: HTMLElement[]
  }
  modules: {
    carousel: InfiniteCarousel
    snapCarousel: SnapCarousel
  }
}

class Show extends Component<ShowType> {
  private showOpen = false
  constructor (el: HTMLElement) {
    super(el)
    this.bindRefs()
  }

  initialized (): void {
    super.initialized()
  }

  getModulesMap (): ModulesMappping {
    if (this.showOpen) {
      if (!mqStore.tabletPortrait.get()) {
        return {
          carousel: ['.show__carousel__inner', InfiniteCarousel]
        }
      }
      return {
        snapCarousel: ['.show__carousel', SnapCarousel]
      }
    }
    return {}
  }

  bindEvents (add: boolean): void {
    const method = bindMethod(add)
    this.refs.lookItems.forEach(item => {
      item[method]('click', this.handleLookItemClick)
    })
    this.refs.closePopin[method]('click', this.handleClosePopin)
    this.refs.carousel[method]('mousewheel', this.handlePopinScroll)
    mqStore.tabletPortrait.toggleListener(add, () => {
      this.bindModules()
    })
  }

  handleLookItemClick = (e: Event) => {
    const itemClicked = e.currentTarget as HTMLElement
    const index = parseInt(itemClicked.dataset.index!, 10)
    scroll.lock(true)
    this.showOpen = true
    this.bindModules()
    if (this.modules.carousel) {
      this.modules.carousel.enabled = false
      if (index !== -1) {
        const size = this.modules.carousel.modules.infiniteLine.itemSizes[index]

        this.modules.carousel.x -= size * index
        this.modules.carousel.targetX -= size * index

        defer(() => {
          this.modules.carousel.enabled = true
          this.el.classList.add('show-popin')
        })
      }
    }
    if (this.modules.snapCarousel) {
      defer(() => {
        this.modules.snapCarousel.refs.slider.scrollLeft = this.modules.snapCarousel.fraction * index
        this.modules.snapCarousel.setActiveTrack(index)
        this.el.classList.add('show-popin')
      })
    }
  }

  handlePopinScroll = (e: Event) => {
    if (!mqStore.tabletPortrait.get())e.preventDefault()
    // this.modules.carousel.x += (e as WheelEvent).deltaY
    // this.refs.carousel.scrollLeft += (e as WheelEvent).deltaY + (e as WheelEvent).deltaX
  }

  handleClosePopin = () => {
    scroll.unlock(true)
    this.el.classList.remove('show-popin')
    this.showOpen = false
    this.bindModules()
  }
}

export default Show
