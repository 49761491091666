import './Footer.scss'
import { ModulesMappping } from 'core/modulesMap'
import { bindMethod } from 'helpers/bind'
import Component from 'navigation/component/Component'
import Form from 'components/form/Form'

type FooterType = {
  modules: {
    newsletterForm: Form
  }
  refs: {
    newsletterInput: HTMLInputElement
  }
}

class Footer extends Component<FooterType> {
  constructor (el: HTMLElement, options: any) {
    super(el, options)
    this.bindEvents()
  }

  getModulesMap (): ModulesMappping {
    return {
      // newsletterForm: ['.footer-newsletter__form', Form]
    }
  }

  bindModules (): void {
    super.bindModules()
  }

  bindEvents (add = true) {
    super.bindEvents(add)
    const method = bindMethod(add)

    if (!this.refs.newsletterInput) return
    this.refs.newsletterInput[method]('keydown', this.onKeydown)
  }

  onKeydown = (event: Event) => {
    if ((event as KeyboardEvent).key === 'Enter') {
      event.preventDefault() // prevent default behavior
      this.modules.newsletterForm.submit(event as SubmitEvent) // submit the form
    }
  }

  initialized (): void {
    this.bindRefs()
    this.bindModules()
    super.initialized()
  }
}

export default Footer
