import InternalRouter from 'navigation/pages/InternalRouter'
import Collection from 'pages/collection/Collection'

import './Search.scss'

class Search extends Collection {
  searchTerms: string

  constructor (...args: ConstructorParameters<typeof InternalRouter>) {
    super(...args)
    this.searchTerms = this.el.getAttribute('data-search') || ''
  }

  shouldRouteInternally (el: HTMLElement, pathName: string): boolean {
    return el?.getAttribute('data-search') === this.searchTerms
  }

  async internalRouting (...args: Parameters<InternalRouter['internalRouting']>) {
    return super.internalRouting(...args)
  }
}

Search.pageName = 'Search'

export default Search
