import Component from 'navigation/component/Component'
import { ProductContext } from 'stores/productStore'
import { dimensions } from 'helpers/resize'
import math from 'helpers/math'
import mqStore from 'stores/mqStore'

type ProductThumbnailsType = {
  context: ProductContext
}

class ProductThumbnails extends Component<ProductThumbnailsType> {
  private screenRatio = 1

  bindEvents (add = true) {
    this.context.scrollGalleryPosition.toggleListener(add, this.onScrollGallery)
  }

  onScrollGallery = (scrollGallery: number) => {
    const size = this.context.scrollGallerySize.get()
    const position = math.wrap(-scrollGallery, 0, size) / size

    if (mqStore.tabletPortrait.get()) return
    this.el.style.setProperty('--scroll-gallery', `${position * 100}%`)
  }

  resize (): void {
    super.resize()

    const screenRatio = dimensions().width / this.context.scrollGallerySize.get()
    const fullscreen = screenRatio > .9
    this.screenRatio = screenRatio

    this.el.classList.toggle('fullscreen', fullscreen)
    this.el.style.setProperty('--scroll-width', `${screenRatio * 100}%`)
  }
}

export default ProductThumbnails
