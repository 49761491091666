import { each, zipObject } from 'lodash-es'
import * as yup from 'yup'
import Component from 'navigation/component/Component'
import scroll from 'core/scroll'
import resize from 'helpers/resize'
import BasePage from 'navigation/pages/Page'
import PageManager from 'navigation/page-manager/PageManager'
import router from 'core/router'
import { bindMethod } from 'helpers/bind'

import validators from './components/validators'

const getFormParameters = (form:HTMLFormElement, submitter:HTMLButtonElement) => {
  // const form = event.currentTarget as HTMLFormElement
  // const submitter = event.submitter as HTMLButtonElement

  const formData = new FormData(form)

  if (submitter?.name) formData.append(submitter.name, submitter.value)

  const urlParameters = '?' + [...formData.entries()]
    .map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1] as string)}`)
    .join('&')

  return {
    formData,
    urlParameters
  }
}

type FormErrors = Record<string, string>

class Form extends Component {
  pageManager: PageManager
  preventDefault: boolean
  submitted: boolean

  method: string = 'POST'
  action: string = '/'

  inputs: HTMLInputElement[] = []
  checkboxes: HTMLInputElement[] = []
  validations: any = {}

  disabled: boolean = false

  schema: yup.ObjectSchema<{
    [key: string]: any
  }> = yup.object().shape({})

  inputsByPath: Record<string, HTMLInputElement[]> = {}
  submitCallback?: (formData: FormData, urlParameters: string) => boolean

  constructor (el: HTMLElement, { parent } : { parent: BasePage }) {
    super(el)
    this.pageManager =
    parent.pageManager as PageManager ||
    parent?.options?.parent?.pageManager as PageManager
    this.preventDefault = true
    this.submitted = false
    if (!this.pageManager) throw new Error('Form must be a child of a Page')

    this.bindRefs()
    this.parseForm()
    this.quickValid()
  }

  parseForm () {
    this.method = (this.el.getAttribute('method') || 'GET').toUpperCase()
    this.action = (this.el.getAttribute('action') || window.location.pathname) as string

    this.inputs = Array.from(this.el.querySelectorAll('[name][data-validation], [name][data-required]'))
    this.checkboxes = Array.from(this.el.querySelectorAll('[type="checkbox"][data-validation]'))
    this.inputsByPath = {}

    this.validations = this.inputs.reduce((memo, input) => {
      const required = input.hasAttribute('data-required')
      const validKey = input.getAttribute('data-validation')
      const name = this.formatName(input.name)
      if (!validKey) return memo
      let validator = validators[validKey]
      if (required) validator = (validators as any).required(validator)
      if (validator) memo[name] = validator

      if (!this.inputsByPath[name]) this.inputsByPath[name] = []
      this.inputsByPath[name].push(input)

      return memo
    }, {} as Record<string, yup.AnyObject>)

    this.updateSchema(this.validations)
  }

  formatName (name: string) {
    return name.replace('[', '___').replace(']', '___')
  }

  updateSchema (validations:yup.ObjectShape) {
    this.schema = yup.object().shape(validations)
  }

  bindEvents (add = true) {
    const method = bindMethod(add)
    this.el[method]('submit', this.onFormSubmit as any)
    this.el[method]('reset', this.onReset)

    this.inputs.forEach(input => {
      input[method]('input', this.onInput)
      input[method]('blur', this.onBlur)
    })
  }

  onInput = (event: Event) => {
    this.emit('input', event)
    this.validateField((event.currentTarget as HTMLInputElement).name, false)
    this.quickValid()
  }

  onBlur = (event: Event) => {
    this.validateField((event.currentTarget as HTMLInputElement).name, true)
  }

  onReset = (event:Event) => {
    this.emit('reset', event)
  }

  validateField (path: string, addError = false) {
    path = this.formatName(path)

    const inputs = this.inputsByPath[path]
    if (!~(this.schema as any)._nodes.indexOf(path)) return
    const values = this.getFormValues()

    this.schema.validateAt(path, values, { context: values })
      .then(() => {
        inputs.forEach((i: HTMLInputElement) => {
          (i.parentNode as HTMLElement)?.style.setProperty('--form-error', '')
          i.classList.remove('error')
        })
      })
      .catch(({ errors }: yup.ValidationError) => {
        const error = errors[0] || ''
        if (addError) {
          inputs.forEach((i: HTMLInputElement) => {
            (i.parentNode as HTMLElement)?.style.setProperty('--form-error', this.getError(error))
            i.classList.add('error')
          })
        }
      })
  }

  quickValid () {
    const values = this.getFormValues()
    return this.schema.validate(values, { abortEarly: false, context: values })
      .then((e) => {
        this.el.classList.add('valid')
        this.el.classList.remove('not-valid')
        return true
      })
      .catch(e => {
        this.el.classList.add('not-valid')
        this.el.classList.remove('valid')
        return false
      })
  }

  getFormValues () {
    const formData = new FormData(this.el as HTMLFormElement)
    const keys = [...formData.keys()]

    /** old version
     * return zipObject(keys.map(k => this.formatName(k)), keys.map(k => formData.get(k))) -> string[]
     *
     *  formData.get() does not support multple values for the same key (ex: checkboxes) -> [][]
     *
     */

    return zipObject(keys.map(k => this.formatName(k)), keys.map(k => formData.getAll(k)))
  }

  getFormData () {
    return new FormData(this.el as HTMLFormElement)
  }

  getFormValuesAsURL () {
    const formData = this.getFormValues()
    return '?' + Object.keys(formData)
      .map(k => {
        const v = formData[k]
        if (Array.isArray(v)) return v.map(vv => `${encodeURIComponent(k)}=${encodeURIComponent(vv as string)}`).join('&')
        return `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
      })
      .join('&')
  }

  setSubmitted (submitted = true) {
    this.submitted = submitted
    this.el.classList.toggle('submitted', submitted)
  }

  onFormSubmit = (event: SubmitEvent) => {
    if (this.submitted) return event.preventDefault()

    if (event.submitter && event.submitter.getAttribute('data-valid') === 'false')
      return this.submit(event)

    if ((event.target as HTMLElement).hasAttribute('data-direct')) return true

    const values = this.getFormValues()

    try {
      this.schema.validateSync(values, { abortEarly: false, context: values })

      this.setSubmitted(true)
      if (this.preventDefault) {
        event.preventDefault()
        this.submit(event)
        this.emit('submit', event)
      }
    } catch (error: any) {
      event.preventDefault()
      if (error.errors || error.inner) return this.updateErrors(this.formatError(error))
      throw error
    }
    // .then(() => {
    // })
    // .catch(e => {
    //   if (e.errors || e.inner) return this.updateErrors(this.formatError(e))
    //   throw e
    // })
  }

  formatError (error: yup.ValidationError) {
    const errors = error.inner && error.inner.length ? error.inner : [Object.assign({}, error)]

    return errors.reduce((memo: Record<string, string>, error: yup.ValidationError) => {
      if (error.path && !memo[error.path] && error.errors) memo[error.path] = error.errors[0]
    }, {} as FormErrors)
  }

  updateErrors (errors: FormErrors) {
    let scrolled = false
    each(this.inputsByPath, (inputs, path) => {
      const error = errors[path]
      inputs.forEach((i: HTMLInputElement) => {
        if (!scrolled && !!error) {
          this.scrollTo(i)
          scrolled = true
        }
        (i.parentNode as HTMLElement).style.setProperty('--form-error', this.getError(error))
        i.classList.toggle('error', !!error)
      })
    })
  }

  scrollTo (el: HTMLElement) {
    if (scroll.locked()) return
    // const inc = sizeStore.notificationHeight.get() + sizeStore.headerHeight.get()
    const top = el.getBoundingClientRect().top + scroll.scrollTop()
    const s = Math.min(Math.max(0, top), (document.scrollingElement?.scrollHeight || 0) - resize.height())
    scroll.scrollTo(s)
  }

  submit (event: SubmitEvent) {
    if (this.disabled) return
    const p = getFormParameters(
      (event ? event.currentTarget : this.el) as HTMLFormElement,
      event?.submitter as HTMLButtonElement
    )

    // const p = getFormParameters(event || ({ currentTarget: this.el } as any))

    const dataAction = event?.submitter?.getAttribute('data-action')
    let action = dataAction || this.action

    if (this.method === 'GET') action += p.urlParameters
    // this.el.classList.add('submitting')

    return Promise.resolve()
      .then(() => {
        if (this.submitCallback) return this.submitCallback(p.formData, p.urlParameters)
        // else this.el.classList.remove('submitting')
        return true
      })
      .then((shouldContinue) => {
        // this.el.classList.remove('submitting')
        if (this.pageManager.state.loading || shouldContinue === false) return

        if (this.method === 'GET') {
          router.navigate(action)
        } else {
          this.pageManager.virtual(action, {
            body: p.formData, method: this.method
          })
        }

        this.submitted = false
      })
  }

  hide () {
    this.disabled = true
  }

  setSubmitCallback (cb: Form['submitCallback']) {
    this.submitCallback = cb
  }

  getError (errorId: any) {
    if (!errorId) return ''
    else return JSON.stringify(errorId)
  }
}

export { getFormParameters }

export default Form
