import InternalRouter from 'navigation/pages/InternalRouter'
import MainPage from 'navigation/pages/MainPage'
import { ProductContext, createProductContext } from 'stores/productStore'
import { ModulesMappping } from 'core/modulesMap'
import ProductStickyBar from 'components/product/product-sticky-bar/ProductStickyBar'
import ProductsRelated from 'components/product/products-related/ProductsRelated'
import ProductInfos from 'components/product/product-infos/ProductInfos'
import ProductGallery from 'components/product/product-gallery/ProductGallery'
import ProductZoom from 'components/product/product-zoom/ProductZoom'
import ProductCover from 'components/product/product-cover/ProductCover'
import { defer, each } from 'lodash-es'
import './Product.scss'
import router from 'core/router'
import { bindEmitterMethod, bindMethod } from 'helpers/bind'
import persistentStore from 'stores/persistentStore'
import { addToWishlist, removeFromWishlist } from 'actions/wishlistActions'

export type ProductType = {
  refs: {
    productSticky: HTMLElement
    addToWishlist: HTMLElement

  }
  modules: {
    productStickyBar: ProductStickyBar
    productCover: ProductCover
    productGallery: ProductGallery
    productZoom: ProductZoom
    productInfos: ProductInfos
    productsRelated: ProductsRelated,
  },
  context: ProductContext
}

class Product extends MainPage<ProductType> {
  getReloadMap (): ModulesMappping {
    return {
      productCover: ['.product-cover', ProductCover],
      productGallery: ['.product-gallery', ProductGallery],
      productZoom: ['.product-zoom', ProductZoom],
      productsRelated: ['.products-related', ProductsRelated],
      productStickyBar: ['.product-sticky-bar', ProductStickyBar],
      productInfos: ['.product-infos', ProductInfos]
    }
  }

  getModulesMap (): ModulesMappping {
    return {
      ...this.getReloadMap(),
      ...super.getModulesMap()
    }
  }

  constructor (...args: ConstructorParameters<typeof InternalRouter>) {
    super(...args)
    this.bindRefs()
    this.createContext()
  }

  initialized (): void {
    super.initialized()
    this.detectWishtlist()
  }

  createContext () {
    const productId = this.el.getAttribute('data-product') as string
    const variantId = this.el.getAttribute('data-variant')

    this.context = createProductContext({
      productId,
      variantId
    })
  }

  toggleWishlist = (event:Event) => {
    event.preventDefault()
    event.stopPropagation()
    const current = persistentStore.wishlist.get()
    const included = current.includes(this.el.dataset.wishlist as string)
    if (included) removeFromWishlist(this.el.dataset.wishlist as string)
    else addToWishlist(this.el.dataset.wishlist as string, this.el.dataset.productName || '', this.el.dataset.productFeatured || '')
  }

  detectWishtlist = () => {
    const current = persistentStore.wishlist.get()
    this.refs.addToWishlist.classList.toggle('toggle', current.includes(this.el.dataset.wishlist!))
  }

  bindEvents (add: boolean): void {
    const emitterMethod = bindEmitterMethod(add)
    const method = bindMethod(add)
    this.modules.productGallery[emitterMethod]('click', this.onCarouselClick)
    this.refs.addToWishlist[method]('click', this.toggleWishlist)
    persistentStore.wishlist.toggleListener(add, this.detectWishtlist)
    this.context.variantLoading.toggleListener(add, this.onVariantLoading)
  }

  onVariantLoading = (loading: boolean): void => {
    this.el.classList.toggle('loading', loading)
  }

  onCarouselClick = (position: number): void => {
    this.modules.productZoom.activate(position)
  }

  shouldRouteInternally (el: HTMLElement): boolean {
    const productId = el?.getAttribute('data-product') as string
    return productId === this.context.productId.get()
  }

  async internalRouting (pathName: string, xhr: XMLHttpRequest) {
    const document = xhr.response as Document

    const page = document.querySelector('.page') as HTMLElement
    this.context.variantId.set(page.getAttribute('data-variant') || '')

    each(this.getReloadMap(), ([selector], key) => {
      const el = page.querySelector(selector) as HTMLElement
      const module = (this.modules as any)[key]
      module?.refresh?.(el)
    })

    defer(() => {
      this.resize()
      this.bindRefs()
      router.updatePageLinks()
    })
  }
}

Product.pageName = 'Product'

export default Product
