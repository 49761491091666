import InfiniteLine from './InfiniteLine'

class VerticalInfiniteLine extends InfiniteLine {
  constructor (el: HTMLElement) {
    super(el, {
      vertical: true
    })
  }
}

export default VerticalInfiniteLine
