import Component from 'navigation/component/Component'
import './CartFooter.scss'
import cart from 'stores/cart'

type CartFooterType = {
  refs:{
    subtotal:HTMLSpanElement
  }
}
export default class CartFooter extends Component<CartFooterType> {
  constructor (el:HTMLElement, options:any) {
    super(el, options)
    this.bindRefs()
    this.bindModules()
  }

  bindEvents (add = true): void {
    cart.loading.toggleListener(add, this.onCartLoading)
  }

  onCartLoading = (loading:boolean) => {
    if (this.refs.subtotal) this.refs.subtotal.classList.toggle('loading', loading)
  }

  hide () {
    this.el.classList.remove('cart-items__active')
  }

  reveal () {
    this.el.classList.add('cart-items__active')
  }

  show () {
    if (!window.location.hash.includes('wishlist'))
      this.reveal()
  }
}
