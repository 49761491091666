import Component from 'navigation/component/Component'
import { bindMethod } from 'helpers/bind'
import './PopinNewsletter.scss'
import store from 'stores'

type PopinNewsletterType = {
  refs: {
    closeNewsletter: HTMLButtonElement[]
  }
}

const popinHashtags = '#newsletter'

class PopinNewsletter extends Component<PopinNewsletterType> {
  constructor (el: HTMLElement) {
    super(el)
    console.log(el.classList)
    this.bindRefs()
    if (el.classList.contains('opened')) store.popin.set('newsletter')
  }

  bindEvents (add = true) {
    const method = bindMethod(add)
    this.refs.closeNewsletter?.forEach((el) => el[method]('click', this.onClose))
    store.popin.listen(this.onPopinUpdate)
    window.addEventListener('hashchange', this.onHashChange)
    this.onHashChange()
  }

  onHashChange = () => {
    const hash = window.location.hash
    if (hash === popinHashtags) store.popin.set('newsletter')
  }

  onPopinUpdate = (popin: string | null) => {
    this.el.classList?.toggle('opened', popin === 'newsletter')
  }

  onClose = (event: Event) => {
    store.popin.set(null)
    const hash = window.location.hash
    if (hash === popinHashtags) window.location.hash = ''
    this.el.classList.remove('submitted')
  }
}

export default PopinNewsletter
