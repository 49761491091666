import { ModulesMappping } from 'core/modulesMap'
import Component, { forceArray } from 'navigation/component/Component'
import { bindMethod } from 'helpers/bind'
import store from 'stores'
import './Header.scss'
import BasicSearch from 'components/basic-search/BasicSearch'
import Form from 'components/form/Form'
import { defer, isString } from 'lodash-es'
import HeaderCart from 'components/header-cart/HeaderCart'
import cart, { CartContent } from 'stores/cart'
import ProductsRelated from 'components/product/products-related/ProductsRelated'
import persistentStore from 'stores/persistentStore'
import SearchSuggestions from 'components/search-sugggestions/SearchSuggestions'
import HeaderAnouncement from 'components/header-announcement/HeaderAnnouncement'
import HeaderPanel from 'components/header-panel/HeaderPanel'
import scroll from 'core/scroll'
import mqStore from 'stores/mqStore'
import anime from 'animejs'

type HeaderType = {
  refs: {
    cartButton: HTMLElement
    cartCount: HTMLElement[]
    wishlistCount: HTMLElement[]
    cartPanel: HTMLElement
    openMenu: HTMLElement
    closeMenu: HTMLElement
    menuItems: HTMLButtonElement[]
    menuToggles: HTMLButtonElement[]
    menuPanels: HTMLElement[]
    marketSelector: HTMLElement
    updateItems: HTMLElement[]
    updateMenu: HTMLElement
    searchButton:HTMLButtonElement
    searchInput: HTMLInputElement
    headerNotifs: HTMLElement[]
    headerLinks: HTMLElement[]
    headerAnnouncement: HTMLElement
  };
  modules: {
    headerCart: HeaderCart
  };
};

class Header extends Component<HeaderType> {
  private updatesIds: string[] = []

  initialized (): void {
    this.bindRefs()
    this.bindModules()
    super.initialized()

    this.refs.wishlistCount.forEach((el) => {
      el.innerHTML = persistentStore.wishlist.get().length.toString()
    })

    this.updatesIds = this.refs.updateItems?.map((el) => el.dataset.id ?? '')
    forceArray(this.refs.headerNotifs).forEach((el) => {
      const hasSeenAll = !this.updatesIds || !!this.updatesIds?.every((id) => persistentStore.updateViewed.get().includes(id))
      el.classList.toggle('not-seen', !hasSeenAll)
    })

    if (!this.refs.headerAnnouncement)
      document.documentElement.classList.add('no-announcement')
  }

  getModulesMap (): ModulesMappping {
    return {
      searchForm: ['.basic-search form', Form],
      basicSearch: ['.basic-search', BasicSearch],
      headerCart: ['.header-cart', HeaderCart],
      productsRelated: ['.products-related', ProductsRelated],
      searchSuggestions: ['.search-suggestions', SearchSuggestions],
      headerAnnouncement: ['.header-announcement', HeaderAnouncement],
      headerPanel: ['.header__panel', HeaderPanel]
    }
  }

  bindEvents (add = true) {
    const method = bindMethod(add)

    this.el[method]('mouseleave', this.onMenuItemOut)
    this.refs.openMenu[method]('click', this.onMobileMenuClick)
    this.refs.closeMenu[method]('click', this.onMobileMenuClick)
    this.refs.cartButton[method]('click', this.onMobileCartClick)
    this.refs.searchButton[method]('click', this.onSearchToggle)
    this.refs.menuItems.forEach((el) => {
      el[method]('mouseenter', this.onMenuItemOver)
      el[method]('click', () => this.onMenuItemClick(el))
    })
    this.refs.menuToggles.forEach((el) => {
      el[method]('click', this.onMenuToggle)
    })
    this.refs.marketSelector[method]('click', this.onMarketSelectorToggle)
    store.menu.toggleListener(add, this.onMenuUpdate)
    cart.content.toggleListener(add, this.onCartUpdate)
    cart.count.toggleListener(add, this.onCartCountUpdate)
    persistentStore.wishlist.toggleListener(add, this.onWishlistUpdate)

    if (this.refs.headerAnnouncement) store.scrollDown.toggleListener(add, this.onScrollUpdate)
    else document.documentElement.style.setProperty('--header-offset', '1')
  }

  onMenuItemClick = (el:HTMLElement) => {
    if (el.dataset.cartView)
      cart.view.set(el.dataset.cartView as 'cart' | 'wishlist')
  }

  onSearchToggle = () => {
    store.menu.set('search')
    this.refs.searchInput?.focus()
  }

  protected scrollOffset: number = 1
  onScrollUpdate = (scrollDown: boolean) => {
    anime({
      targets: this,
      scrollOffset: scrollDown ? 0 : 1,
      duration: 300,
      easing: 'easeOutSine',
      update: () => {
        document.documentElement.style.setProperty('--header-offset', this.scrollOffset.toString())
      }
    })
  }

  onWishlistUpdate = (wishlist: string[]) => {
    this.refs.wishlistCount.forEach((el) => {
      el.innerHTML = persistentStore.wishlist.get().length.toString()
    })
  }

  onMenuItemOver = (event: Event) => {
    const target = (event.target as HTMLElement).parentElement as HTMLElement
    const menuId = target.dataset.menu as string
    store.menu.set(menuId)
    store.panel.set(null)
  }

  onMenuToggle = (event: Event) => {
    const target = (event.target as HTMLElement).parentElement as HTMLElement
    const menuId = target.dataset.panel as string
    if (store.menu.get() === menuId) store.menu.set(true)
    else store.menu.set(menuId)
  }

  onMarketSelectorToggle = (event: Event) => {
    defer(() => {
      const popin = store.popin.get()
      if (popin === 'market-selector') { store.popin.set(null) } else {
        store.menu.set(null)
        store.popin.set('market-selector')
        scroll.unlock()
      }
    })
  }

  onMenuItemOut = (event: Event) => {
    if (mqStore.tabletPortrait.get()) return
    store.menu.set(null)
    scroll.unlock()
  }

  onMobileMenuClick = () => {
    store.menu.set(!store.menu.get())
    store.popin.set(null)
    store.panel.set(null)
    if (store.menu.get())
      scroll.lock()

    else scroll.unlock()
  }

  onMobileCartClick = () => {
    store.menu.set('cart')
    // scroll.lock()
  }

  onMenuUpdate = (menuId: string | boolean | null) => {
    this.el.classList.toggle('opened', !!menuId)
    if (isString(menuId)) {
      if (menuId === 'updates') this.checkAndMarkUpdates()
      this.el.setAttribute('data-opened', menuId)
    } else { this.el.setAttribute('data-opened', menuId ? 'true' : 'false') }

    this.refs.menuItems.forEach((el) => {
      const parent = el.parentElement as HTMLElement
      parent?.classList.toggle('active', parent.dataset.menu === menuId)
      parent?.classList.toggle('inactive', !!menuId && parent.dataset.menu !== menuId)
    })

    this.refs.menuPanels.forEach((el) => {
      el?.classList.toggle('active', el.dataset.panel === menuId)
    })
  }

  onCartUpdate = (cart: CartContent | null) => {
    if (cart === null || !cart['main-cart-header']) return
    this.refs.cartPanel.innerHTML = cart['main-cart-header']
    this.bindModules()
  }

  onCartCountUpdate = (count: number) => {
    this.refs.cartCount.forEach((el) => {
      el.innerHTML = count.toString()
    })
  }

  checkAndMarkUpdates () {
    if (!this.refs.updateItems) return
    const newArr: string[] = []
    const updatesSeen = persistentStore.updateViewed.get().slice(0)

    this.refs.updateItems.forEach((el) => {
      el.dataset.id && newArr.push(el.dataset.id)
      if (!updatesSeen.includes(el.dataset.id as string)) el.classList.add('not-seen')
      else el.classList.remove('not-seen')
    })

    persistentStore.updateViewed.set([...newArr])
    this.refs.headerNotifs.forEach((el) => {
      el.classList.toggle('not-seen', !this.updatesIds.every((id) => persistentStore.updateViewed.get().includes(id)))
    })
  }
}

export default Header
