import { throttle } from 'lodash-es'

import detect from './detect'

type Dimensions = {
  width: number
  innerWidth: number
  height: number
  innerHeight: number

  ratio: number
  scrollWidth: number
}

type DimensionsRef = {
  dimensions?: Dimensions
}

type Cache = DimensionsRef & {
  [key: string]: boolean
}

type Listener = {
  resize: () => any
}

let cache: Cache = {} as Cache
let history = {} as DimensionsRef

let root: HTMLElement
let ignoreScrollResize = true

const dimensions = () => {
  if (cache.dimensions) return cache.dimensions

  const dimensions = {
    width: !root ? window.innerWidth : root.offsetWidth,
    innerWidth: document.body.offsetWidth,
    // height: Math.min(document.documentElement.offsetHeight, window.innerHeight)
    height: window.innerHeight
  } as Dimensions

  dimensions.innerHeight = dimensions.height
  dimensions.scrollWidth = dimensions.width - dimensions.innerWidth

  // Ignore ios resize on scroll (wheen bottom bar disappear)
  if (ignoreScrollResize &&
    detect.iphone &&
    history.dimensions &&
    history.dimensions.width === dimensions.width)
    dimensions.innerHeight = history.dimensions.innerHeight

  dimensions.ratio = dimensions.width / dimensions.height
  history.dimensions = Object.assign({}, dimensions)

  return (cache.dimensions = dimensions)
}

const width = () => dimensions().width
const height = () => dimensions().height
const ratio = () => dimensions().ratio
const scrollWidth = () => dimensions().scrollWidth
const testMQ = (mq:string) => () => cache[mq] || (cache[mq] = window.matchMedia(mq).matches)

const clear = () => {
  history = cache
  cache = {} as Cache
}

let listeners = [] as Listener[]
const resize = (event? : Event) => {
  _resize(event)
}

const _resize = throttle((event? : Event) => {
  clear()
  listeners.forEach((listener) => listener && listener.resize && listener.resize())
}, 50)

window.addEventListener('resize', resize)
window.addEventListener('orientationchange', resize)

const add = (listener: Listener) => listeners.push(listener)
const remove = (listener: Listener) => (listeners = listeners.filter(item => item !== listener))

const setRoot = (r:HTMLElement) => {
  root = r
  clear()
  resize()
}

const setIgnoreScrollResize = (i:boolean) => (ignoreScrollResize = !!i)

export {
  dimensions
}

export default {
  dimensions,
  scrollWidth,
  outerWidth,
  ratio,
  width,
  height,
  clear,
  add,
  remove,
  setRoot,
  setIgnoreScrollResize,
  resize,
  testMQ
}
