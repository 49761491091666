import { ModulesMappping } from 'core/modulesMap'
import InternalRouter from 'navigation/pages/InternalRouter'
import MainPage from 'navigation/pages/MainPage'
import { bindEmitterMethod, bindMethod } from 'helpers/bind'

import './Brand.scss'
import BrandItem from 'components/brand-item/BrandItem'
import Button from 'components/button/Button'

type BrandType = {
  refs:{
    toggles:HTMLElement[]
  }
  modules: {
    items:BrandItem[],
    buttons:Button[],
  }
}

class Brand extends MainPage<BrandType> {
  private bar: HTMLElement = null!

  getModulesMap (): ModulesMappping {
    return {
      ...super.getModulesMap(),
      items: ['.brand-item', BrandItem],
      buttons: ['.brand-bar__toggle', Button]
    }
  }

  constructor (...args: ConstructorParameters<typeof InternalRouter>) {
    super(...args)
    this.bindRefs()
  }

  initialized (): void {
    this.bindModules()
    super.initialized()
    this.buildBar()
  }

  bindEvents (add = true): void {
    const method = bindMethod(add)
    this.refs.toggles.forEach((toggle, index) => {
      toggle[method]('click', () => this.onToggleClick(index))
    })
  }

  buildBar () {
    this.bar = document.createElement('div')
    this.bar.classList.add('brand-bar')
    this.refs.toggles.forEach((toggle, index) => {
      const button = toggle
      this.bar.appendChild(button)
    })
    this.el.appendChild(this.bar)
    this.bar.children[0].classList.add('brand-bar__toggle-active')
    this.modules.items[0].el.classList.toggle('brand-item__active')
    this.modules.items[0].active = true
  }

  onToggleClick = (index:number) => {
    this.refs.toggles.forEach((toggle, i) => {
      toggle.classList.toggle('brand-bar__toggle-active', i === index)
    })
    this.modules.items.forEach((item, i) => {
      item.el.classList.toggle('brand-item__active', i === index)
      item.active = i === index
    })
  }
}

Brand.pageName = 'brand'

export default Brand
