import { defer } from 'lodash-es'
import Component, { BaseComponentType } from 'navigation/component/Component'
import VirtualPageManager from 'navigation/page-manager/VirtualPageManager'
import InternalPage from 'navigation/pages/InternalPage'
import MainPage from 'navigation/pages/MainPage'
import { RequestOptions } from 'navigation/page-manager/PageManager'
import { State } from 'helpers/state'
import scroll from 'core/scroll'
import { ModulesMappping } from 'core/modulesMap'

type InternalRouterType<Type extends BaseComponentType> = Type & {
  refs: { internalRouter: HTMLElement } & Type['refs']
  modules: { virtualPageManager: VirtualPageManager } & Type['modules']
}

class InternalRouter <
  Type extends BaseComponentType = BaseComponentType
> extends MainPage <InternalRouterType<Type>> {
  getModulesMap (): ModulesMappping {
    return {}
  }

  getDefaultPageClass () {
    return InternalPage
  }

  getPageSelector () {
    return '.internal-page'
  }

  constructor (...args: ConstructorParameters<typeof MainPage>) {
    super(...args)
    this.bindRefs()
  }

  bindModules () {
    Component.prototype.bindModules.call(this)
    if (!this.modules.virtualPageManager) this.modules.virtualPageManager = this.initContentRouter()
    defer(() => this.bindEvents(true))
  }

  initContentRouter () {
    const store = new State<string | null>(null)

    return new VirtualPageManager(
      store,
      this.refs.internalRouter,
      this.getPageSelector(),
      this.getDefaultPageClass(),
      { pageManager: this.pageManager, parent: this }
    )
  }

  shouldRouteInternally (el: HTMLElement, pathName: string) {
    return true
  }

  async scrollToTop () {
    if (document.scrollingElement) await scroll.scrollTo(0)
  }

  async internalRouting (pathName: string, xhr: XMLHttpRequest, requestOptions: RequestOptions) {
    await this.scrollToTop()
    this.modules.virtualPageManager.forceRouteUpdate(pathName, xhr, requestOptions)
  }
}

export default InternalRouter
