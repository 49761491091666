import Component from 'navigation/component/Component'
import store from 'stores'
import { template } from 'lodash-es'
import router from 'core/router'
import { ToasterType } from 'stores/store'
import './Toaster.scss'

type ToasterComponetType = {
  refs: {
    toasterContent: HTMLElement
  }
}

class Toaster extends Component<ToasterComponetType> {
  timeout?: NodeJS.Timeout

  constructor (el: HTMLElement) {
    super(el)
    this.bindRefs()
  }

  bindEvents (add = true) {
    store.toaster.toggleListener(add, this.showToaster)
  }

  showToaster = (toaster: ToasterType) => {
    if (!toaster) return
    this.el.classList.add('visible')
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(this.hideToaster, 3000)
    const t = `
      <% if (toaster.image) { %>
        <img src="<%= toaster.image %>" />
      <% } %>
      <h4><%= toaster.title %></h4>
      <% if (toaster.link) { %>
        <a href="<%= toaster.link.href %>" data-navigo><%= toaster.link.label %></a>
      <% } %>
      <% if (toaster.externalLink) { %>
        <a href="<%= toaster.externalLink.href %>"><%= toaster.externalLink.label %></a>
      <% } %>
    `
    this.refs.toasterContent.innerHTML = template(t)({ toaster })
    router.updatePageLinks()
  }

  hideToaster = () => {
    this.el.classList.remove('visible')
    store.error.set(null)
  }
}

export default Toaster
