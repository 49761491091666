import Component from 'navigation/component/Component'
import './HeaderAnnouncement.scss'
import { ModulesMappping } from 'core/modulesMap'
import InfiniteCarousel from 'components/infinite-carousel/InfiniteCarousel'

export type HeaderAnnouncementType = {
  modules: {
    infiniteCarousel: InfiniteCarousel
  }

}

export default class HeaderAnouncement extends Component<HeaderAnnouncementType> {
  getModulesMap (): ModulesMappping {
    return {
      infiniteCarousel: ['.message', InfiniteCarousel]
    }
  }

  initialized (): void {
    this.bindModules()
    this.modules.infiniteCarousel.canDrag = false
    super.initialized()
  }
}
