import { each, mapValues } from 'lodash-es'

import { createStore } from '../helpers/state'

const initialState = {
  updateViewed: [] as string[],
  wishlist: [] as string[],
  cookieConsent: null
}

type PersistentState = {
  updateViewed: string[]
  wishlist: string[]
  cookieConsent: boolean | null
}

const persistentStore = createStore<PersistentState>(mapValues(initialState, (v, key) => {
  const value = localStorage.getItem(key)
  return value ? JSON.parse(value) : v
}))

each(persistentStore, (s, k) => {
  s.listen((v: any) => {
    localStorage.setItem(k, JSON.stringify(v))
  })
})

export default persistentStore
