import store from 'stores/store'

const callback = () => {
  store.blurredOverlay.set(!!(store.panel.get() || store.menu.get() || store.popin.get() || store.filtersOpened.get()))
}

store.menu.listen(callback)
store.panel.listen(callback)
store.popin.listen(callback)
store.filtersOpened.listen(callback)
