import { createStore, StoreType } from 'helpers/state'

type HomepageState = {
  blocksEl:HTMLElement[]
}
export type HomepageStore = StoreType<HomepageState>

const createHomepageStore = (options: HomepageState) : HomepageStore => {
  return createStore<HomepageState>({
    ...options
  })
}

const homepageStore = createHomepageStore({
  blocksEl: []
})

export default homepageStore
