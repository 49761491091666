import ProductsRelated from 'components/product/products-related/ProductsRelated'
import { ModulesMappping } from 'core/modulesMap'
import Component from 'navigation/component/Component'
import './HeaderCart.scss'
import cart from 'stores/cart'
import CartItem from 'components/cart/cart-item/CartItem'
import ReinsuranceTabs from 'components/reinsurance-tabs/ReinsuranceTabs'
import Accordion from 'components/accordion/Accordion'

type HeaderCartType = {
  refs:{
    list:HTMLElement
  }
}
export default class HeaderCart extends Component<HeaderCartType> {
  initialized (): void {
    this.bindRefs()
    this.bindModules()
    super.initialized()
  }

  getModulesMap (): ModulesMappping {
    return {
      productsRelated: ['.products-related', ProductsRelated],
      cartReinsurance: ['.reinsurance-tabs', ReinsuranceTabs],
      CartItem: ['.cart-item', CartItem],
      cartReinsuranceMobile: ['.header-cart__footer-accordion', Accordion]
    }
  }

  bindEvents (add = true): void {
    cart.loading.toggleListener(add, this.onCartLoading)
  }

  onCartLoading = (loading:boolean) => {
    this.refs.list.classList.toggle('header-cart__list__loading', loading)
  }

  flush (): void {
    super.flush()
  }
}
