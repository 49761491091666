import { bindMethod } from 'helpers/bind'
import Component from 'navigation/component/Component'

type HeaderPanelType = {
  refs: {
    headerLinks: HTMLElement[]
  }
}

class HeaderPanel extends Component<HeaderPanelType> {
  initialized (): void {
    this.bindRefs()
    super.initialized()
  }

  bindEvents (add = true): void {
    const method = bindMethod(add)

    this.refs.headerLinks?.forEach?.((el) => {
      el[method]('mouseenter', this.onHeaderLinkOver)
      el[method]('mouseleave', this.onHeaderLinkLeave)
    })
  }

  onHeaderLinkOver = (event: Event) => {
    // add a class all the other links that are not hovered
    const target = event.target as HTMLElement
    this.refs.headerLinks.forEach((el) => {
      el.classList.toggle('notHovered', el !== target)
    })
  }

  onHeaderLinkLeave = () => {
    this.refs.headerLinks.forEach((el) => {
      el.classList.remove('notHovered')
    })
  }
}

export default HeaderPanel
