import { defer } from 'lodash-es'
import anime from 'animejs'
import { BaseComponentType } from 'navigation/component/Component'
import scroll from 'core/scroll'

import Page from './Page'

export default class MainPage<
  Type extends BaseComponentType = BaseComponentType
> extends Page<Type> {
  /* LOAD */
  async askPreload (previousPage?: string) {
    this.el.classList.add('appearing')
    await super.askPreload(previousPage)
  }

  /* SHOW */
  show (previousPage?:string) {
    this.resize()
    scroll.lock()

    const delay = previousPage ? 0 : 800

    return Promise.resolve()
      .then(() => anime({
        targets: this.el,
        opacity: [0, 1],
        translateX: ['2%', '0%'],
        easing: 'easeOutCubic',
        duration: 400,
        delay,
        changeBegin: () => this.showChildren()
      }).finished)
      .then(() => {
        this.el.style.transform = ''
        scroll.unlock()
        defer(() => this.resize())
        this.navigateToHash()
      })
  }

  navigateToHash () {
    if (window.location.hash)
      document.querySelector(window.location.hash)?.scrollIntoView({ behavior: 'smooth' })
  }

  getChildrenShowDelay () {
    return 1
  }

  showChildren () {
    setTimeout(() => this.invoke('show'), this.getChildrenShowDelay())
  }

  transitionComplete () {
    this.el.classList.remove('appearing')
    super.transitionComplete()
  }

  /* HIDE */

  hide (nextPage?:string) {
    this.el.classList.add('disappearing')
    document.documentElement.style.setProperty('--scroll', `${scroll.scrollTop()}px`)

    return anime({
      targets: this.el,
      opacity: [1, 0],
      translateX: ['0%', '-2%'],
      duration: 400,
      easing: 'easeInCubic',
      delay: 1
    }).finished
  }
}
